import {useDispatch, useSelector} from "react-redux";
import {
    setForgetPasswordOnchange
} from "../../redux/actions/forgetPasswordActions/forgetPasswordAction";

export  const Hooks=()=> {
    const dispatch = useDispatch()
    // use Selector redux
    const values = useSelector((state) => state.forgetPasswordReducer)
   
//******************connexion************************//
    const forgetPasswordOnChange = (event) => {
       
        
        dispatch(setForgetPasswordOnchange({
            forgetPasswordNameChange: event.target.name,
            forgetPasswordValueChange: event.target.value
        }));
    };


    return({
        forgetPasswordOnChange,
        values
    })
}
