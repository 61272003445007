import React from 'react';

import './resetPassword.css'

import {FormResetPassword} from "./components/formResetPassword";


export const ResetPassword =()=>{
    return(
        <div className={"divGlobalResetPassword"}>
            <FormResetPassword/>
        </div>
    )
}
