import React, { useState } from 'react';
import { FormGroup, FormControlLabel, Checkbox, Switch } from '@material-ui/core';
import { InputText } from '@empreinte/components.input-text';
import { Label } from "@empreinte/components.label";
import { Card, CardContent } from '@material-ui/core';
import '../../css/step2_Client_Conf.css'
import { useDispatch, useSelector } from 'react-redux';
import { appAction } from '../../redux/actions/appAction';

const SelectList = ({ name, keys }) => {
  const dispatch = useDispatch();

  const step2DataModule = useSelector(state => state.app.step2_data_module);
  const isDisabled = step2DataModule[name]['disabled'];
  const [isActive, setIsActive] = useState(!isDisabled);
  const selectedRedux = step2DataModule[name]['selected'];
  const [selected, setSelected] = useState(selectedRedux);

  const handleCheckboxChange = key => event => {
    const newSelected = [...selected];
    if (event.target.checked) {
      newSelected.push(key);
    } else {
      const index = newSelected.indexOf(key);
      newSelected.splice(index, 1);
    }
    setSelected(newSelected);

    dispatch(appAction('step2-setSelected', { name: name, selected: newSelected }));
  };

  const handleSwitchChange = (event) => {
    const { checked } = event.target
    setIsActive(checked);

    dispatch(appAction('step2-setdisabled', { name: name, disabled: !checked }));
  };

  const selectedModules = Object.keys(step2DataModule)
    .filter(key => selected.includes(key))
    .reduce((obj, key) => {
      obj[key] = step2DataModule[key];
      return obj;
    }, {});

  const handleModuleChange = (event, moduleName) => {
    const { name, value } = event.target;

    dispatch(appAction('step2-module', {
      formModule: {
        ...step2DataModule,
        [moduleName]: {
          ...step2DataModule[moduleName],
          [name]: value,
        },
      }
    }));
  }
  const handleCheckAllChang = (event) => {
    if (event.target.checked) {
      setSelected(keys);
      dispatch(appAction('step2-setSelected', { name: name, selected: keys }));
    } else {
      setSelected([]);
      dispatch(appAction('step2-setSelected', { name: name, selected: [] }));
    }
   
  };

  return (
    <div className={`card-module${name === 'configuration' || name === 'display' ? ' long' : ''}`} key={name}>
      <div className='switch-container'>
        <Switch checked={isActive} onChange={handleSwitchChange} />
        <Label className='switchLabel'>{name}</Label>
      </div>

      <div className='chebox-container'>
        <div className='checkboxs'>
          <FormGroup disabled={!isActive} className='checkAll'>
            {keys.length > 1 && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selected.length === keys.length}
                    onChange={handleCheckAllChang}
                    disabled={!isActive}
                  />
                }
                label="Check all"
              />
            )}
          </FormGroup>
          <FormGroup
            disabled={!isActive}
          >
            {keys.map(key => (
              <FormControlLabel
                key={key}
                control={
                  <Checkbox
                    checked={selected.includes(key)}
                    onChange={handleCheckboxChange(key)}
                    disabled={!isActive}
                  />
                }
                label={key}
              />
            ))}
          </FormGroup>
        </div>
        <Card className={`card-mod${name === 'configuration' ? ' long-mod-card' : ''}`} style={{ opacity: isActive ? 1 : 0.5 }}>
          <CardContent >
            <form >
              {Object.entries(selectedModules).map(([moduleName, moduleData]) => {
                return (
                  <div key={moduleName}>
                    <Label className='modules-title'>{moduleName}</Label>
                    {Object.entries(moduleData).map(([key, value]) => {
                      if (key === 'disabled' || key === 'selected') {
                        return null;
                      }
                      return (
                        <div key={key} className='config-card module-step'>
                          <Label className='card-label' >{key}:</Label>
                          <InputText
                            className='InputText-card' name={key}
                            handleChange={(event) => handleModuleChange(event, moduleName)}
                            value={value}
                            disabled={!isActive}
                          ></InputText>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default SelectList;