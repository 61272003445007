import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import {  Card, CardContent } from "@material-ui/core";
import { useDispatch  } from "react-redux";
import {
  setForgetPasswordConstraintDataOnchange
} from "../../redux/actions/forgetPasswordActions/forgetPasswordAction";;

export const RecoveryPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBackLogin = ()=>{
dispatch(setForgetPasswordConstraintDataOnchange({constraintDataNameChange:"passwordSent",constraintDataValueChange:false}));
  navigate('/');
  }

  return (
    <Card className="card-forget-password">
     <CardContent className="card-content-recovery-password">
        <label htmlFor="recovery-email" className={"recovery-email-label"}>
        Email de réinitialisation du mot de passe envoyé.
        </label>
        <label
          variant="body1"
          className={"recovery-check-email"}
        >
        Veuillez vérifier votre boîte de réception e-mail.
        </label>
            <Button
              onClick={handleBackLogin}
              className="btn-forget-password"
              fullWidth
              variant="contained"
              color="primary"
            >
          Back To LogIn
            </Button>
            </CardContent>
    </Card>

  );
};
