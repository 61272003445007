import gql from "graphql-tag";

export const graphQL_shema = ()=> {

    const Connexion = gql`       
    mutation($input:LoginInput!) {
        login(input:$input, , isDoubleAuthenticated:true) {
            Status
            Message
            Token
            FirstName
            LastName
            Thumbnail
            Id
            Username
        }
    }

      
    `;

    const tokenVerification = gql`
    mutation($input:VerifSecureTokenFoActionInput!) {
        VerifSecureTokenFoAction(input:$input) {
         Code
         Message
       }
    }
    `;

    return({
        Connexion,
        tokenVerification
    })
}