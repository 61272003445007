import { InputText } from '@empreinte/components.input-text';
import { Label } from "@empreinte/components.label";
import '../css/step1_add_client.css';
import { appAction } from '../redux/actions/appAction'
import { useDispatch, useSelector } from 'react-redux';

const Step1 = () => {
  const dispatch = useDispatch();
  const data = useSelector(state => state.app);
  const step1Data = data.step1_data;
  const step1ConfigDNS = data.step1_config_DNS;
  const items = Object.keys(step1Data);
  const hasId = items.includes("id");
  const Dns_items = Object.keys(step1ConfigDNS);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "Nom de client") {
      dispatch(
        appAction("step2-config", {
          formConfig: { ...data.step2_data_card, "CLIENT_NAME": value },
        })
      );
    }
    dispatch(appAction('step1', { ...step1Data, [name]: value }));
  };

  const handleInputChangeDNS = (event) => {
    const { name, value } = event.target;
    dispatch(appAction('set_step1_config_DNS', { name: name, value: value}));
  };


  return (
    <div className="my-grid-container">
      {items.map((item, index) => {
        if (item === "id" || item === "olderUserName") {
          return null;
        }
        const requiredItems = ["Nom de client", "database_password", "database_password_webtv"];
        const isRequired = requiredItems.includes(item);
        return (
          <div key={index} className="grid-item" >
            <Label
              id={`item-${index}`}
            >
              {item}
              {isRequired ? <span style={{ color: 'red' }} className="required-star"> *</span> : null}
            </Label>
            <InputText
              value={step1Data[item]}
              name={item}
              handleChange={handleInputChange}
              fullWidth={true}
            >
            </InputText>
          </div>)
      })}
      {hasId ? null : (
        Dns_items.map((item, index) => {
          const requiredItems = ["Api DNS", "Bo DNS", "Fo DNS"];
          const isRequired = requiredItems.includes(item);
          return (
            <div key={index} className='grid-item'>
              <Label id={`item-${index}`}>
                {item}
                {isRequired ? <span style={{ color: 'red' }} className="required-star"> *</span> : null}
              </Label>
              <InputText
                value={step1ConfigDNS[item]}
                name={item}
                handleChange={handleInputChangeDNS}
                fullWidth={true}
              />
            </div>
          )
        })
      )}
    </div>
  );
}

export default Step1;