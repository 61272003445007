import {useMutation} from "@apollo/react-hooks";
import {graphQL_shema} from "./graphQL";
import {useDispatch} from "react-redux";
import {
    setForgetPasswordConstraintDataOnchange
} from "../../redux/actions/forgetPasswordActions/forgetPasswordAction";;


export const GraphQLFetchData=(values)=> {

    const dispatch = useDispatch();


    const [ForgetPassword] = useMutation(graphQL_shema().forgetPassword, {
        variables: {input :values.forgetPassword}
        ,
        onCompleted: async (data) => {

            if (data.requestRestePassword.status===200)
                {
                    dispatch(setForgetPasswordConstraintDataOnchange({constraintDataNameChange:"loadingForgetPassword",constraintDataValueChange:false}));
                    dispatch(setForgetPasswordConstraintDataOnchange({constraintDataNameChange:"passwordSent",constraintDataValueChange:true}));
                    dispatch(setForgetPasswordConstraintDataOnchange({constraintDataNameChange:"errMessage",constraintDataValueChange:""}));
                }
        },
        onError(err) {
            dispatch(setForgetPasswordConstraintDataOnchange({constraintDataNameChange:"passwordSent",constraintDataValueChange:false}));
            dispatch(setForgetPasswordConstraintDataOnchange({ constraintDataNameChange: "loadingForgetPassword", constraintDataValueChange: false }));
            dispatch(setForgetPasswordConstraintDataOnchange({constraintDataNameChange:"errMessage",constraintDataValueChange:"Aucun utilisateur trouvé"}));
        }
        
    });
    return({
        ForgetPassword,
    })
}
