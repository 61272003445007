const initialState = {
    loginCredential: {
        username: '',
        Password: '',
    },
    UserConstantsData: {
        storageUsername: "",
        storagePassword: "",
        storageIsRememberMe: false,
    },
    constraintData: {
        loadingConnexion: false,
        connexionError: false,
        isRememberMe: false,
        message: "",
        thumbnail: "",
        username: "",
        firstName: "",
        lastName: "",
        id: "",

    },
    appState: { loggedIn: false },
    authToken: '',
};

const connexionReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ConnexionOnchange':
            return {
                ...state,
                loginCredential: {
                    ...state.loginCredential,
                    [action.payload.ConnexionNameChange]: action.payload.ConnexionValueChange,
                },
            };
        case "SET_PersistActions":
            const { persistNameChange, persistValueChange } = action.payload;
            const ConnexionOnOnchangeObj = { ...state.UserConstantsData, [persistNameChange]: persistValueChange }
            return {
                ...state,
                UserConstantsData: ConnexionOnOnchangeObj
            }
        case "SET_ConnexionConstraintDataOnchange":
            return {
                ...state,
                constraintData: {
                    ...state.constraintData,
                    [action.payload.constraintDataNameChange]: action.payload.constraintDataValueChange,
                }
            }
        case "SET_AppSetLogin":
            return {
                ...state,
                appState: { loggedIn: true },
                authToken: action.payload
            }
        case "SET_AppSetLogout":
            return {
                ...state,
                appState: { loggedIn: false },
                authToken: '',
            }
        default:
            return state;
    }
};

export default connexionReducer;