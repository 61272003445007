import '../../components/css/clients.css';
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { appAction  } from '../redux/actions/appAction';
import axios from "axios";
import {Datagrid} from "@empreinte/components.datagrid";
import {Button} from '@empreinte/components.button';
import {Loader} from "@empreinte/components.loader";
import {Label} from "@empreinte/components.label";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ReplayIcon from '@mui/icons-material/Replay';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Box from '@material-ui/core/Box';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import {parseConfString,parseModuleString,convert_module_string_To_Object} from "../redux/reducers/states";
import { GlobalHooks } from '../../utils/Hooks';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import useAxiosInstance from '../../interceptor.js';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Clients = ({searchValue}) => {
  const axiosInstance  = useAxiosInstance();
  const apiClientDetail =process.env.REACT_APP_CLIENT_DETAIL;
  const apiClientUpdate =process.env.REACT_APP_CLIENT_UPDATE;
  const apiClientDelete =process.env.REACT_APP_DELETE_CLIENT;
  const apiAllClient =process.env.REACT_APP_ALL_CLIENT;
  const [clientData, setClientData] = useState([]);
  const [clientToDelete, setClientToDelete] = useState("");
  const[totalClients,setTotalClients]=useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch=useDispatch();
  const data = useSelector(state => state.app);
  const deleteSnack = data.deleteSnack['msg'];
   const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  let [isOpenLoader, setIsOpenLoader] = useState(false);
  const [filteredClientData, setFilteredClientData] = useState([]);
  const [selectedRows,setSelectedRows]=useState([]);
  const [multiClient, setMultiClient]=useState([]);
  const {cookies} =GlobalHooks();
  const  authToken = cookies.authToken;

  const handleSearch = (searchValue) => {
    const modifiedSearchValue = searchValue.trim(); 
    let filteredClients =[] ;
    
    if (modifiedSearchValue === "") {
      filteredClients = clientData;
    } else if (!isNaN(modifiedSearchValue)) {
      filteredClients = clientData.filter(client =>
        client.id.toString().startsWith(modifiedSearchValue)
      );
    } else {
      filteredClients = clientData.filter(client =>
        client.client_name.toLowerCase().startsWith(modifiedSearchValue.toLowerCase())
      );
    }
    
    setFilteredClientData(filteredClients);
  }
  
  const handleClickOpen = (clientName) => {
    setClientToDelete(clientName);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setClientToDelete("");
    setMultiClient([]);
  };

  const handleConfirm = () => {
    setIsOpenLoader(true);
    axiosInstance.delete(`${apiClientDelete}/${clientToDelete}`,{
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })
    .then((response) => {
      let res = response?.data?.message;
      if (res === "success delete") {
        localStorage.setItem('msg', JSON.stringify(`Client "${clientToDelete}" est supprimé avec succès`));
      } else if (res === "error delete"){
        localStorage.setItem('msg', JSON.stringify(`Erreur lors de la suppression du client "${clientToDelete}"`));
      }
      setIsOpenLoader(false);
      localStorage.setItem('SideBarIndex', JSON.stringify(1));
      window.location.reload();
    })
    .catch(function (error) {console.log(error)});
    setOpen(false);
  };

  const handleConfirmMultiple=()=>{
    setIsOpenLoader(true);
  let succItems = [];
  let errItems = [];

  const deletePromises = multiClient.map(client => {
    return axiosInstance.delete(`${apiClientDelete}${client}`,{
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    })
      .then((response) => {
        let res = response?.data?.message;
        if (res === "success delete") {
          succItems.push(client);
        } else if (res === "error delete") {
          errItems.push(client);
        }
      })
      .catch(function (error) {console.log(error)});
  });

  Promise.all(deletePromises)
  .then(() => {
    if (succItems.length > 0) {
      const succMessage = `Les clients "${succItems.join(' , ')}" sont supprimés avec succès`;
      localStorage.setItem('msgSucc', JSON.stringify(succMessage));
    }

    if (errItems.length > 0) {
      const errMsg = `Les clients "${errItems.join(' , ')}" ne sont pas supprimés, une erreur est survenue`;
      localStorage.setItem('msgErr', JSON.stringify(errMsg));
    }
    localStorage.setItem('SideBarIndex', JSON.stringify(1));
    setIsOpenLoader(false);

    window.location.reload(); 
  })
    }

 const handleDeleteMultiple =()=>{
    let NamesToDelete=[]
    NamesToDelete = selectedRows.map((rowId) => {
    const row = clientData.find((client) => client.id === rowId);
    return row.client_name;
  });

if (NamesToDelete.length === 1) {
  handleClickOpen(NamesToDelete[0]);
}else{
  setMultiClient(NamesToDelete);
  setOpen(true);
}
 }

useEffect(() => {
    axiosInstance.get(apiAllClient, {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      })
      .then((response) => {
        let res = response?.data;
        setClientData(res?.data);
        setTotalClients(res?.pagination?.totalClients)
        setFilteredClientData(res?.data)
      })
      .catch(function (error) {});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleSearch(searchValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

const columns = [
{ field: "id", headerName: "ID",flex:1 },
{ field: "client_name", headerName: "Client Name",flex:1},
{field: "actions",headerName: "Actions",flex:1,disableClickEventBubbling: true,
renderCell: (params) => {
return (
<>
<span className="modif-supp">Modifier :</span>
<EditIcon
  style={{ cursor: "pointer", marginRight: 10, pointerEvents: selectedRows.length > 0 ? "none" : "auto", opacity: selectedRows.length > 0 ? 0.5 : 1 }}
  onClick={(event) => HandleEdit(event,params.row.client_name)}/>
<>
<span className="modif-supp">Mettre à jour :</span>
<ReplayIcon
  style={{ cursor: "pointer", marginRight: 10, pointerEvents: selectedRows.length > 0 ? "none" : "auto", opacity: selectedRows.length > 0 ? 0.5 : 1 }}
  onClick={(event) => handleUpdate(event,params.row.client_name)}/>
<></>
<span className="modif-supp">| Supprimer :</span>
     <DeleteIcon 
        style={{ cursor: "pointer", pointerEvents: selectedRows.length > 0 ? "none" : "auto", opacity: selectedRows.length > 0 ? 0.5 : 1 }}
        onClick={()=>handleClickOpen(params.row.client_name)} 
      />
     <div className="dialog">
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth={true}
        BackdropProps={{ sx: { backgroundColor: 'rgba(0, 0, 0, 0.2) !important'}}}
       >
        <DialogTitle id="responsive-dialog-title" className="dialog-title">
        Supprimer
        </DialogTitle>
        <DialogContent>
        {multiClient.length > 1 ? (
          <DialogContentText className="dialog-text">
          Etes-vous sûr de vouloir supprimer les clients :
          <strong> {multiClient.join(' , ')}</strong>
          </DialogContentText>
      ) : (
        <DialogContentText className="dialog-text">
          Etes-vous sûr de vouloir supprimer le client :
          <strong> {clientToDelete}</strong>
          </DialogContentText>
      )}
      </DialogContent>
        <DialogActions className="dialog-btn-container">
          <Button
          title="non"
          className="dialog-btn no-btn" 
           component="label" 
           onClick={handleClose}>
          </Button>
          <Button
           title="oui"
           className="dialog-btn yes-btn"  
           component="label" 
           onClick={multiClient.length > 1 ?handleConfirmMultiple:handleConfirm} >
          </Button>
        </DialogActions>
      </Dialog>
      </div>
    </>
</>
);
},
},
];

const rows = filteredClientData
  .slice((currentPage - 1) * pageSize, currentPage * pageSize)
  .map((client) => {
    const row = { id: client.id };
    columns.forEach((column) => {
      if (client.hasOwnProperty(column.field)) {
        row[column.field] = client[column.field];
      }
    });
    return row;
  });

const HandleEdit = (event,clientName) => {
  axiosInstance.get(`${apiClientDetail}/${clientName}`, {
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  })
  .then((response) => {
    let {id, client_name: Nom_utilisateur, database_password,database_host,database_port,database_driver,database_webtv, 
      database_password_webtv,database_host_webtv, database_port_webtv,database_name,database_user,database_user_webtv, username, ...step1Data} = response?.data.client;
    const updatedStep1Data = {
      id: id.toString(),
      'Nom de client': Nom_utilisateur,
      olderUserName: Nom_utilisateur,
      database_password,database_host,database_port: database_port.toString(),database_driver,
      database_webtv:database_webtv.replace('sf_', ''), 
      database_password_webtv,database_host_webtv,database_port_webtv: database_port_webtv.toString(),
      ...step1Data,
    };

    const clientConfig =response?.data.clientConfig;
   const confObj = parseConfString(clientConfig.config);
  
   const mod =clientConfig.module ;
   const obj =parseModuleString(mod)
  const module_final =convert_module_string_To_Object(data.step2_data_module,obj)

    dispatch(appAction('step1', updatedStep1Data));
    dispatch(appAction("step2-config", {
      formConfig: confObj,
    }))
    dispatch(appAction('step2-module', {
      formModule: module_final
    }));
    dispatch(appAction('step2-data', { name: 'id', value: clientConfig.id.toString()}));
    dispatch(appAction('step2-data', { name: 'olderUserName', value: Nom_utilisateur}));
    dispatch(appAction('setCounter', { index: 0 }));
  })
  .catch(function (error) {console.log(error)});
  dispatch(appAction('setDashbordStep', { index: 0 }));
  dispatch(appAction('setStep', { index: 0 }));
};

const handleCloseSnackbar = (event,reason) => {
  if (reason === 'clickaway') {
    return;
  }
  dispatch(appAction('setDeleteClient', { msg:""}));
};

//Update Modifications
const [updateSnack, setUpdateSnack] = useState("");
const handleCloseUpdateSnackbar = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setUpdateSnack("");
};
const handleUpdate = async (event, clientName) => {
  try {
    const response = await axiosInstance.put(`${apiClientUpdate}/${clientName}`, null, {
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    });

    let res = response?.data?.message;

    if (res === "Success: Update Client") {
      localStorage.setItem('msgUpdate', JSON.stringify(`Client "${clientName}" updated successfully`));
      res = `Client "${clientName}" updated successfully`
    } else if (res === "error update") {
      localStorage.setItem('msgUpdateErr', JSON.stringify(`Error updating client "${clientName}"`));
    }

    setUpdateSnack(res);
    dispatch(appAction('setUpdateClient', { msg: res }));
    dispatch(appAction('setSideBarIndex', { index: 1 }));
    setIsOpenLoader(false);
    
  } catch (error) {
    console.log(error);
    setIsOpenLoader(false);
  }
};

const handlePageChange = (page) => {
  setCurrentPage(page);
};


return (
   <div className="clients">
 <div className='label-client'>
          <Label>Tableau des clients enregistrés</Label>
          </div>
          {selectedRows.length > 0 && (
            <Button
          variant="outlined"
          startIcon={<DeleteIcon />}
          className="delete-multiple"
          onClick={handleDeleteMultiple}>
            delete
        </Button>
      )}         
   <Snackbar open={deleteSnack !== "" && deleteSnack.includes("avec succès")} autoHideDuration={4000} onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal:'right'}}>
                <Alert onClose={handleCloseSnackbar} severity="success">
                    {deleteSnack}
                </Alert>
            </Snackbar>
   <Snackbar open={updateSnack !== ""} autoHideDuration={4000} onClose={handleCloseUpdateSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleCloseUpdateSnackbar} severity={updateSnack.includes("success") ? "success" : "error"}>
          {updateSnack }
        </Alert>
      </Snackbar>

  <Loader isOpen={isOpenLoader}/>
  <Datagrid
   height={'650px'}
  className="datagrid-clients"
  columns={columns}
  rows={rows}
  pageSize={pageSize}
  setPageSize={setPageSize}
  currentPage={currentPage}
  setCurrentPage={setCurrentPage}
  onPageChange={handlePageChange}
  totalEntities={totalClients}
  ShowName="show"
  EntriesName="entries"
  OfPageName="of"
  checkboxSelection
  checkboxSelect={(newSelection) => {
    setSelectedRows(newSelection)
  }}
/>

<Snackbar open={deleteSnack !== "" && !deleteSnack.includes("avec succès")} autoHideDuration={4000} onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'top', horizontal:'right'}}>
                <Alert onClose={handleCloseSnackbar} severity="error">
                    {deleteSnack}
                </Alert>
            </Snackbar>
</div>
);
};

export default Clients;