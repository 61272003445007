import * as React from 'react';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import NotesIcon from '@mui/icons-material/Notes';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import './css/sideBar.css';
import LogoEmpreinte from '../assets/LogoEmpreinte.webp';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Divider from '@mui/material/Divider';

const drawerWidth = 260;

const SideBar = (props) => {
  const { window } = props;

  const drawer = (
    <div className='drawer'>
      <div className="image-container">
        <img src={LogoEmpreinte} alt="background" />
      </div>
        <List className='sideBar-list-one' sx={{ marginTop: "32px" }}>
          {['Dashboard', 'Clients', 'SQL'].map((text, index) => (
            <ListItem key={text} disablePadding
      onClick={(event) => props.handleListItemClick(event, index, 1)}
              className='lists-items'
              sx={{
                backgroundColor: props.selectedIndex === index ? '#020E21' : 'transparent',
                borderRadius: '8.83405px',
                '&:hover': {
                  backgroundColor: props.selectedIndex === index ? '#020E21' : '',
                },
              }}
            >
              <ListItemButton sx={{ padding: '21px !important' }}>
                <ListItemIcon sx={{ color: props.selectedIndex === index ? 'white' : 'black' }}>
                  {index === 0 && <DashboardOutlinedIcon />}
                  {index === 1 && <PersonOutlineOutlinedIcon />}
                  {index === 2 && <NotesIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ color: props.selectedIndex === index ? 'white' : 'black' }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        <List className='sideBar-list-two' sx={{ alignSelf: 'self-end' }}>
          <Divider sx={{ width: '190px', marginLeft: '32px !important' }} />
          {['Help', 'Log Out'].map((text, index) => (
            <ListItem key={text} disablePadding
              className='lists-items'
              sx={{
                backgroundColor: props.selectedIndex === index + 3 ? '#020E21' : '',
                borderRadius: '8.83405px',
                '&:hover': {
                  backgroundColor: props.selectedIndex === index + 3 ? '#020E21' : '',
                },
              }}
              onClick={(event) => props.handleListItemClick(event, index, 2)}>
              <ListItemButton sx={{ padding: '21px !important' }}>
                <ListItemIcon sx={{ color: props.selectedIndex === index + 3 ? 'white' : 'black' }}>
                  {index === 0 && <HelpOutlineOutlinedIcon />}
                  {index === 1 && <LogoutOutlinedIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ color: props.selectedIndex === index + 3 ? 'white' : 'black' }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      sx={{
        width: drawerWidth,
        flexShrink: { sm: 0 },
        display: 'flex',
        '@media (max-width: 775px)': {
          display: 'none',
        },
      }}
      aria-label="dashboard-side-bar"
    >
      <CssBaseline />
      <Drawer
        container={container}
        variant="temporary"
        open={props.mobileOpen}
        onClose={props.handleDrawerToggle}
        ModalProps={{
          keepMounted: true, 
        }}
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  )
}

export default SideBar; 