import '../../css/step2_Client_Conf.css'
import { InputText } from '@empreinte/components.input-text';
import { Label } from "@empreinte/components.label";
import { Card, CardContent } from '@material-ui/core';
import { appAction } from '../../redux/actions/appAction'
import { useDispatch, useSelector } from 'react-redux';
import { Button } from "@empreinte/components.button"
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import React from 'react';
import StepTwoModule from './step2_module';
import { useState } from 'react';
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const Step2 = () => {
  const dispatch = useDispatch();
  const [isAdding, setIsAdding] = useState(false);
  const [newVariable, setNewVariable] = useState("");
  const step2DataCard = useSelector((state) => state.app.step2_data_card);



  const handleCardChange = (event) => {
    const { name, value } = event.target;

    dispatch(
      appAction("step2-config", {
        formConfig: { ...step2DataCard, [name]: value },
      })
    );
  };

  const handleAddClick = () => {
    setIsAdding(true);
  };

  const handleConfirmClick = () => {
    const add = newVariable.trim();
    if (add !== "") {
      const { CLIENT_NAME, ...restData } = step2DataCard;
      const updatedFormConfig = {
        CLIENT_NAME,
        [add]: '',
        ...restData,
      };
      dispatch(
        appAction("step2-config", {
          formConfig: updatedFormConfig,
        })
      );
      setNewVariable("");
      setIsAdding(false);
    }
  };

  const handleDeleteClick = (variableToDelete) => {
    const { [variableToDelete]: _, ...updatedFormConfig } = step2DataCard;
    dispatch(appAction("step2-config", {
      formConfig: updatedFormConfig,
    }));
  };

  const handleNewVariableChange = (event) => {
    setNewVariable(event.target.value);
  };


  return (
    <div className='client_config_container'>
      <div className='card-label-container'>
        <Label>Configuration</Label>
      </div>
      <Card className='card-config' >
        <CardContent >
          <form >
            <div className='add-container'>
              {isAdding ? (
                <>
                <div className='New-field-label'>
                <Label >Nouveau Champ</Label>
                </div>
                  <InputText
                    className='InputText-card'
                    name={newVariable}
                    handleChange={handleNewVariableChange}
                    value={newVariable}
                    style={{ gridColumn: '1' }}
                  ></InputText>
                  <div className='conf-cancel-btns'>
                    <IconButton
                      title="confirm"
                      className="confirm_butt"
                      onClick={handleConfirmClick}
                    >
                      <CheckIcon color='info' />
                    </IconButton>
                    <IconButton
                      title="Cancel"
                      className="cancel-btn"
                      onClick={() => {
                        setNewVariable("");
                        setIsAdding(false);
                      }}
                    >
                      <CancelIcon color='info' />
                    </IconButton>
                  </div>
                </>
              ) :
                <Button
                  title="add"
                  variant="contained"
                  component="label"
                  startIcon={<AddIcon />}
                  className="add_butt"
                  onClick={handleAddClick}
                ></Button>
              }

            </div>
            {Object.entries(step2DataCard).map(([variable, value]) =>  {
  if (variable === "CLIENT_NAME") {
    return (
      <div key={variable} className='clientName-config'>
        <Label className='card-label clientName-label'>{variable} : </Label>
        <Label className='card-label' >{value}</Label>
      </div>
    );
  } else {
    return (
      <div key={variable} className='config-card config-step'>
        <Label className='card-label' >{variable}:</Label>
        <div className='input-delete'>
          <InputText
            className='InputText-card' name={variable}
            handleChange={(event) => handleCardChange(event)}
            value={value}
          ></InputText>
          <DeleteIcon
            className='delete-icon'
            onClick={() => handleDeleteClick(variable)}
          />
        </div>
      </div>
    );
  }
            })}

          </form>
        </CardContent>
      </Card>
      <StepTwoModule />
    </div>
  )
}



export default React.memo(Step2);