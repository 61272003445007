import React, { useState, useEffect } from "react";
import { Hooks } from "../utils/hooks";
import '../forgetPassword.css'
import {
    setForgetPasswordConstraintDataOnchange,
} from "../../redux/actions/forgetPasswordActions/forgetPasswordAction";
import {
    TextField,
    Button,
    InputAdornment,
    FormControl,
    Link,
    Typography
} from "@mui/material";
import { AccountCircle} from "@material-ui/icons";
import { FormHelperText, Card, CardContent } from "@material-ui/core";
import { GraphQLFetchData } from "../utils/graphQLFetchData";
import { useDispatch  } from "react-redux";

export const ForgetPasswordComponent = () => {
    const { forgetPasswordOnChange, values } = Hooks();
    const [verif, setVerif] = useState(false);
    const { ForgetPassword } = GraphQLFetchData(values);
    const dispatch = useDispatch();

    useEffect(() => {
        if (values.forgetPassword.email && values.forgetPassword.email.length > 0) {
            setVerif(false);
        }
    }, [values.forgetPassword.email]);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (
            !verif &&
            values.forgetPassword.email &&
            values.forgetPassword.email.length > 0) {
            dispatch(setForgetPasswordConstraintDataOnchange({ constraintDataNameChange: "loadingForgetPassword", constraintDataValueChange: true }));
            ForgetPassword();
        } else {
            setVerif(true);
        }

    }

    return (
        <Card className="card-forget-password">
            <CardContent className="card-content-forget-password">
                <form className="forget-password-form" onSubmit={handleSubmit}>
                    <label htmlFor="reset-password" className={"reset-password-label"}>
                        Email de réinitialisation de mot de passe
                    </label>
                    <FormControl className="formControl-forget-password">
                    <label htmlFor="email" className={"rest-email-label"}>
                        Indiquez votre adresse e-mail
                    </label>
                        <TextField
                            fullWidth
                            name="email"
                            className="champ-email"
                            onChange={forgetPasswordOnChange}
                            placeholder={"Votre adresse e-mail"}
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle className="icon-forgetPassword-inut"/>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {verif &&
                            (!values.forgetPassword.email || values.forgetPassword.email.length <= 0) && (
                                <FormHelperText className="helper-text" style={{ color: 'red' }}>
                                    Please enter your email!
                                </FormHelperText>
                            )}
                            <Typography variant="body1" className="error">
                                {values.constraintData.errMessage}
                            </Typography>
                        <Button
                            fullWidth
                            className={"btn-forget-password"}
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={values.constraintData.loadingForgetPassword}
                        >
                           {values.constraintData.loadingForgetPassword ? 'Loading...' : 'Envoyer email de réinitialisation'}  
                        </Button>
                    </FormControl>
                    <Link href="/" variant="body2" className={"spn_chbx"}>
                        Retour à la page de connexion
                    </Link>
                </form>
            </CardContent>
        </Card>
    );
};
