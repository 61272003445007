import { useSelector } from 'react-redux';
import { Label } from "@empreinte/components.label";
import '../css/confirmation.css'
import moduleKeys from '../redux/reducers/module_cleaning'
import { convert_module_to_string, convert_config_to_string } from '../redux/reducers/states';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { appAction } from '../redux/actions/appAction';
import { Tab, Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import { Card,CardContent } from '@material-ui/core';
import ShortTextIcon from '@mui/icons-material/ShortText';

const Confirmation = () => {
  const data = useSelector(state => state.app);
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);

  const module_as_string = convert_module_to_string(moduleKeys());
  const config_as_a_string = convert_config_to_string(data.step2_data_card);

  useEffect(() => {
      dispatch(appAction('step2-data', { name: 'module', value: module_as_string }));
      dispatch(appAction('step2-data', { name: 'config', value: config_as_a_string }));
  }, [config_as_a_string, dispatch, module_as_string]);

   const second_tabs_components = (data_module) =>{
      const newobj = {};
      Object.keys(data_module).forEach(key => {
        const { disabled, ...selectedValues } =data_module[key];
        if (disabled=== false) {
          newobj[key] = selectedValues.selected;
        }
      });
      return (
        <Card className='confirm-container-step2'>
        <CardContent className='confirm-champs-step2'>
          {Object.keys(newobj).map((key) => (
          <div key={key} className='texts-step2'>
            <span className='champ-step2'> {key} : </span>
            <span className='champ-value-step2'>{newobj[key].join(' / ')}</span>
          </div>
        ))}
          </CardContent>
        </Card>
      )
    } 


  const first_tabs_componenent = (data) => {
    return (
         <Card className='confirm-container'>
      <CardContent className='confirm-champs'>
      <Label className='texts'>
         <span className='champ'>Nom de client:</span>
         <span className='champ-value'><ShortTextIcon sx={{marginRight:'10px'}}/> {data['Nom de client']}</span>
      </Label>
      <Label className='texts'>
         <span className='champ'>Database_password:</span> 
         <span className='champ-value'><ShortTextIcon sx={{marginRight:'10px'}}/> {data['database_password']}</span>
      </Label>
      <Label className='texts'>
        <span className='champ'>Database_webtv:</span>
        <span className='champ-value'><ShortTextIcon sx={{marginRight:'10px'}}/>{data['database_webtv'].length > 0 ? `sf_${data['database_webtv']}` : data['database_webtv']}</span>   
      </Label>
      <Label className='texts'>
        <span className='champ'>Database_password_webtv:</span>
        <span className='champ-value'><ShortTextIcon sx={{marginRight:'10px'}}/> {data['database_password_webtv']}</span>   
      </Label>
      </CardContent>
    </Card>
    );
  }

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box >
      <div className='confirm-label-container'>
        <Label>Confirmation</Label>
      </div>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        className='confirm-tabs'
        centered
      >
        <Tab label="Client information" sx={{marginRight:"12px"}} wrapped/>
        <Tab label="Client configuration information" wrapped />
      </Tabs>
      {selectedTab === 0 && first_tabs_componenent(data.step1_data) }
      {selectedTab === 1 && second_tabs_components(data.step2_data_module)}
    </Box>
  )
}
export default Confirmation;