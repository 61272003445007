import { Connexion_Onchange_TYPE, App_Set_Login_TYPE ,App_Set_Logout_TYPE ,Connexion_Constraint_Data_Onchange_TYPE,PersistActions_TYPE} from './connexionActionTypes';

export const setConnexionOnchange = (e) => {
  return {
    type: Connexion_Onchange_TYPE,
    payload:e
  };
};

export function setPersistActions(e){

    return{
        type: PersistActions_TYPE,
        payload:e,
    }
}

export const setAppSetLogin = (e) => {
    return {
      type: App_Set_Login_TYPE,
      payload:e
    };
  };

  export const setAppSetLogout = (e) => {
    return {
      type: App_Set_Logout_TYPE,
      payload:e
    };
  };

  export const setConnexionConstraintDataOnchange = (e) => {
    return {
      type: Connexion_Constraint_Data_Onchange_TYPE,
      payload:e
    };
  };