import React, {Suspense} from 'react';
import App from './App';
import ApolloClient from 'apollo-client';
import {InMemoryCache} from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import {ApolloProvider} from '@apollo/react-hooks';
import {setContext} from 'apollo-link-context';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import {Spin} from "antd";
import { CookiesProvider } from 'react-cookie';
import Cookies from 'js-cookie';
import { legacy_createStore as createStore } from 'redux';
import { Provider } from "react-redux";
import rootReducer from './components/redux/reducers/rootReducer';


const httpLink = createUploadLink({ uri:"https://api.webtv-solution.dev/query" });
const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
  // Create an error link
  const errorLink = onError(({ networkError, graphQLErrors }) => {
    if (networkError) {
      // Handle network errors
      console.log('Network error:', networkError);
    }
  
    if (graphQLErrors) {
      for (const error of graphQLErrors) {
        if (error.extensions && error.extensions.code === 'UNAUTHENTICATED') {
          // Handle authentication errors
          console.log('Authentication error:', error.message);
          
        }
      }
    }
  });
  
  // Create a context link for adding the token to the request headers
  const authLink = setContext((_, { headers }) => {
    const token = Cookies.get('authToken');
  
    return {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });
  
  // Create the Apollo Client instance
  const client = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache(),
  });


function AppoloProviderFunc() {
  return (
    <Suspense fallback={<Spin className="Spin"><div></div></Spin>}>
      <Provider store={store}>
        <ApolloProvider client={client}>
          <CookiesProvider>
            <App />
          </CookiesProvider>
        </ApolloProvider>
      </Provider>
    </Suspense>
  );
}

export default AppoloProviderFunc;


