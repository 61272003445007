import React from 'react';
import '../forgetPassword.css'
import {Hooks} from "../utils/hooks";
import {RecoveryPassword} from "./recoveryPassword";
import {ForgetPasswordComponent} from "./forgetPassword";
import LogoEmpreinte  from "../../../assets/LogoEmpreinte.webp";

export const FormForgetPassword =()=>{

    const{values}=Hooks()

    return(
        <div
            className={"Connection-form-forget-pass"}
        >
         <div className="image-container-login">
                <img src={LogoEmpreinte} alt="img-contaoner-login" />
            </div>
            {!values.constraintData.passwordSent ?
                <ForgetPasswordComponent />
                :
                <RecoveryPassword/>
            }
        </div>
    )
}
