import React, { useEffect, useState } from "react";
import { Card, CardContent, FormHelperText } from "@material-ui/core";
import './connexion.css';
// import svg from "../../assets/Group.svg";
import LogoEmpreinte from '../../assets/LogoEmpreinte.webp';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField';
import { AccountCircle, LockOutlined } from "@material-ui/icons";
import {
    Grid,
    FormControlLabel,
    Typography,
    Checkbox,
} from "@mui/material";
import { Hooks } from "./utils/hooks";
import { setConnexionOnchange ,setPersistActions} from "../redux/actions/connexionActions/connexionAction";
import { useDispatch, useSelector } from "react-redux";
import { AES, enc } from 'crypto-js';

const Connexion = () => {
    const encryptionSecretKeyEmpreinte ="Empreinte Sousse 391998 @"
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const [verif, setVerif] = useState(false);
    const loading = useSelector(
        (state) => state.connexion.constraintData
    );
    const userData = useSelector((state) => state.connexion.loginCredential);
    const rememberMe = useSelector(
        (state) => state.connexion.UserConstantsData.storageIsRememberMe
    );
    const remember = localStorage.getItem("storageIsRememberMe");
    const {
        onFinish,
        // onFinishFailed,
        connexionOnChange,
        connexionOnChangeButton,
        toForgotPassword,
    } = Hooks();

    useEffect(() => {
        if (JSON.parse(remember) === true) {
            dispatch(setPersistActions({persistNameChange:"storageIsRememberMe",persistValueChange:true}));
            const username = localStorage.getItem("storageUsername");
            const password = localStorage.getItem("storagePassword");
            const decryptedUsername = AES.decrypt(username, encryptionSecretKeyEmpreinte).toString(enc.Utf8);
            const decryptedPassword = AES.decrypt(password,encryptionSecretKeyEmpreinte).toString(enc.Utf8);
            console.log(decryptedUsername)
          dispatch(setConnexionOnchange({
            ConnexionNameChange: "username",
            ConnexionValueChange: decryptedUsername,
          }));
          dispatch(setConnexionOnchange({
            ConnexionNameChange: "Password",
            ConnexionValueChange: decryptedPassword,
          }));
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    useEffect(() => {
        if (userData && userData.Password && userData.username) {
            setVerif(false);
        }
    }, [userData]);

    const handleClickShowPassword = () => {
        setShowPassword((showPassword) => !showPassword);
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if (
            !verif &&
            userData.Password &&
            userData.Password.length > 0 &&
            userData.username &&
            userData.username.length > 0
        ) {
            onFinish({ username: userData.username, password: userData.Password });
        } else {
            setVerif(true);
        }
    };


    return (
        <div className="login-container">
            <div className="image-container-login">
                <img src={LogoEmpreinte} alt="img-contaoner-login" />
            </div>
            <Card className="card-login">
                <CardContent className="card-content-login">
                    <form onSubmit={handleFormSubmit}>
                        <label htmlFor="username" className="bienvenue">
                            Bienvenue
                        </label>
                        <label htmlFor="username" className="connexion">
                            Connexion
                        </label>

                        <FormControl className="formControl-login">
                            <label htmlFor="username" className="input_label">
                                Nom utilisateur
                            </label>
                            <TextField
                                placeholder="Nom d'utilisateur"
                                variant="outlined"
                                value={userData.username}
                                name="username"
                                onChange={connexionOnChange}
                                id="outlined-required"
                                className="input-login"
                                InputProps={{
                                    startAdornment: (
                                        <AccountCircle className="icon-Login-Dashboard" />
                                    ),
                                }}
                                fullWidth
                            />
                            {verif &&
                                (!userData.username || userData.username.length <= 0) && (
                                    <FormHelperText className="helper-text" style={{ color: 'red' }}>
                                        Please enter your username!
                                    </FormHelperText>
                                )}

                            <label htmlFor="password" className="input_label">
                                Mot de passe
                            </label>
                            <OutlinedInput
                                placeholder="Mot de passe"
                                variant="outlined"
                                name="Password"
                                value={userData.Password}
                                className="input-login"
                                onChange={connexionOnChange}
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                startAdornment={<LockOutlined className="icon-Login-Dashboard" />}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={(event)=>event.preventDefault()}
                                            onMouseUp ={(event)=>event.preventDefault()}
                                            edge="end"
                                        >
                                            {showPassword ? (
                                                <Visibility className="icon-Login-visibility" />
                                            ) : (
                                                 <VisibilityOff className="icon-Login-visibility" />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                fullWidth
                            />
                            {verif &&
                                (!userData.Password || userData.Password.length <= 0) && (
                                    <FormHelperText className="helper-text" style={{ color: 'red' }}>
                                        Please enter your password!
                                    </FormHelperText>
                                )}
                            <Grid container className="rememberMeDiv">
                                <Grid>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="isRememberMe"
                                                checked={rememberMe}
                                                onChange={connexionOnChangeButton}
                                            />
                                        }
                                        label="Se souvenir de moi"
                                    />
                                </Grid>
                                <Grid className="rememberMeDivCol2">
                                    <Typography
                                        variant="body1"
                                        onClick={toForgotPassword}
                                    >
                                        Mot de passe oublié
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography variant="body1" className="error">
                                {loading.message}
                            </Typography>
                            <Button
                                type="submit"
                                variant="contained"
                                className="connexion-btn"
                                disabled={loading.loadingConnexion}
                            >
                                {loading.loadingConnexion ? 'Loading...' : 'Login'}
                            </Button>
                        </FormControl>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
};

export default Connexion;






