import { step1_data, step2_config, step2_module } from './states'

const initialState = {
    oldFieldsImpactClientConfig:{
        oldApiDNS:"",
        oldBoDNS:"",
        oldFoDNS:"",
    },
    selectedSideBar: 0,
    activeStep: 0,
    counter: 0,
    modifCounter: 0,
    step1_data: step1_data,
    step1_config_DNS: {
        'Api DNS': '',
        'Bo DNS': '',
        'Fo DNS': '',
    },
    step2_data_module: step2_module,
    step2_data_card: step2_config,
    step2_data: {
        'client_name': '',
        'module': '',
        'config': ''
    },
    deleteSnack: {
        'msg': '',
        'severity': ''
    },
}

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setOldFields':
            return {
                ...state,
                oldFieldsImpactClientConfig:{
                    ...state.oldFieldsImpactClientConfig,
                    [action.payload.name]: action.payload.value
                }
            }
        case 'step2-data':
            return {
                ...state,
                step2_data: {
                    ...state.step2_data,
                    [action.payload.name]: action.payload.value
                }
            }
        case 'setDeleteClient':
            return {
                ...state,
                deleteSnack: {
                    ...state.deleteSnack,
                    msg: action.payload.msg,
                    severity: action.payload.severity
                }
            };
        case 'setStep':
            return {
                ...state,
                selectedSideBar: action.payload.index
            };
        case 'setCounter':
            return {
                ...state,
                counter: action.payload.index
            };
        case 'setModifCounter':
            return {
                ...state,
                modifCounter: action.payload.index
            };
        case 'setDashbordStep':
            return {
                ...state,
                activeStep: action.payload.index
            };
        case 'step1':
            return {
                ...state,
                step1_data: action.payload
            };
        case 'set_step1_config_DNS':
            return {
                ...state,
                step1_config_DNS:{
                    ...state.step1_config_DNS,
                    [action.payload.name]: action.payload.value
                }
            };
        case 'step2-config':
            return {
                ...state,
                step2_data_card: action.payload.formConfig,
            };
        case 'step2-module':
            return {
                ...state,
                step2_data_module: action.payload.formModule
            };
        case 'step2-setSelected':
            return {
                ...state,
                step2_data_module: {
                    ...state.step2_data_module,
                    [action.payload.name]: {
                        ...state.step2_data_module[action.payload.name],
                        selected: action.payload.selected
                    },
                },
            }
        case 'step2-setdisabled':
            return {
                ...state,
                step2_data_module: {
                    ...state.step2_data_module,
                    [action.payload.name]: {
                        ...state.step2_data_module[action.payload.name],
                        disabled: action.payload.disabled
                    },
                },
            }
        case 'step3':
            return {
                ...state,
            }
        default:
            return state
    }

}

export default appReducer;