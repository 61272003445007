import { ForgetPasswordOnchange_TYPE , ForgetPasswordConstraintDataOnchange_TYPE} from './Types';

export function setForgetPasswordOnchange(e){
    return{
        type: ForgetPasswordOnchange_TYPE,
        payload:e,
    }
}

//*******************Constraint Data actions*******************//

export function setForgetPasswordConstraintDataOnchange(e){
    return{
        type:ForgetPasswordConstraintDataOnchange_TYPE,
        payload:e
    }
}
