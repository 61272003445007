import { useCookies } from 'react-cookie';

export const GlobalHooks = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['language','authToken','thumbnail','validated','username','id']);
    return {
        cookies,
        setCookie,
        removeCookie
      };
    };
    