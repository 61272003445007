import React from 'react';
import './forgetPassword.css'
import {FormForgetPassword} from "./components/formForgetPassword";


export const ForgetPassword =()=>{
    return(
        <div className={"divGlobalForgetPassword"} >
            <FormForgetPassword />
        </div>
    )
}
