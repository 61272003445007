import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useSelector } from 'react-redux';
import '../css/dashbord.css';

export default function HorizontalLinearStepper(props) {
  const activeStep = useSelector(state => state.app.activeStep)
  return (
    <Box sx={{ width: '70%', justifySelf: 'center' }}>
      <Stepper activeStep={activeStep} className={activeStep === props.steps.length ? "success-step" : "nothing"}>
        {props.steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (props.isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}  >
              <StepLabel {...labelProps}  >{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

    </Box>
  );
}