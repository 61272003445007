import { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { graphQL_shema } from "./graphQL";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { setConnexionConstraintDataOnchange, setAppSetLogin, setAppSetLogout, setConnexionOnchange } from "../../redux/actions/connexionActions/connexionAction";
import { message } from 'antd';
import { GlobalHooks } from "../../../utils/Hooks";
import { AES } from 'crypto-js';

export const GraphQLFetchData = () => {
    const { setCookie } = GlobalHooks();
    const encryptionSecretKeyEmpreinte = "Empreinte Sousse 391998 @"
    // Read Data from Hooks
    // use Query to fetch Data
    const [verificationToken, setVerificationToken] = useState(false);
    const token = localStorage.getItem('jwtToken') ? localStorage.getItem('jwtToken') : '';

    const navigate = useNavigate()
    const dispatch = useDispatch()
    // use Selector redux
    const values = useSelector((state) => state.connexion.loginCredential);
    const RememberMe = useSelector((state) => state.connexion.UserConstantsData.storageIsRememberMe);

    const [Connexion] = useMutation(graphQL_shema().Connexion, {
        variables: { input: { ...values } },
        fetchPolicy: "no-cache",
        onCompleted: async (data) => {
            console.log("onCompleted!=> ", data)
            if (data.login.Status === 200) {
                const encryptedUsername = AES.encrypt(values.username, encryptionSecretKeyEmpreinte).toString();
                const encryptedPassword = AES.encrypt(values.Password, encryptionSecretKeyEmpreinte).toString();
                navigate("/main")
                dispatch(setAppSetLogin(data.login.Token));
                setCookie('authToken', data.login.Token, { path: '/' });
                setCookie('thumbnail', data.login.Thumbnail, { path: '/' });
                setCookie('validated', true, { path: '/' });
                setCookie('username', data.login.Username, { path: '/' });
                setCookie('id', data.login.Id, { path: '/' });

                dispatch(setConnexionConstraintDataOnchange({
                    constraintDataNameChange: "id",
                    constraintDataValueChange: data.login.Id
                }));
                dispatch(setConnexionConstraintDataOnchange({
                    constraintDataNameChange: "username",
                    constraintDataValueChange: data.login.Username
                }));
                dispatch(setConnexionConstraintDataOnchange({
                    constraintDataNameChange: "thumbnail",
                    constraintDataValueChange: data.login.Thumbnail
                }));
                dispatch(setConnexionConstraintDataOnchange({
                    constraintDataNameChange: "firstName",
                    constraintDataValueChange: data.login.FirstName
                }));
                dispatch(setConnexionConstraintDataOnchange({
                    constraintDataNameChange: "lastName",
                    constraintDataValueChange: data.login.LastName
                }));
                dispatch(setConnexionConstraintDataOnchange({
                    constraintDataNameChange: "message",
                    constraintDataValueChange: ""
                }))

                if (RememberMe === true) {
                    localStorage.setItem('storageIsRememberMe', true);
                    localStorage.setItem('storageUsername', encryptedUsername);
                    localStorage.setItem('storagePassword', encryptedPassword);
                } else if (RememberMe === false) {
                    localStorage.removeItem('storageIsRememberMe')
                    localStorage.removeItem('storageUsername')
                    localStorage.removeItem('storagePassword')
                }

            }
            else if (data.login.Status === 400) {
                // console.log(data.login.Message,'message');
                dispatch(setConnexionConstraintDataOnchange({ constraintDataNameChange: "loadingConnexion", constraintDataValueChange: false }))
                dispatch(setConnexionConstraintDataOnchange({ constraintDataNameChange: "message", constraintDataValueChange: "Bad Request" }))
            }


            else if (data.login.Status === 500) {

                message.error('This is an error message');
                dispatch(setConnexionConstraintDataOnchange({ constraintDataNameChange: "message", constraintDataValueChange: true }))
                dispatch(setConnexionConstraintDataOnchange({ constraintDataNameChange: "message", constraintDataValueChange: "Internal Server Error" }))


            }

            dispatch(setConnexionConstraintDataOnchange({ constraintDataNameChange: "loadingConnexion", constraintDataValueChange: false }))

        },
        onError(err) {
            // console.log('error',err);
            dispatch(setConnexionConstraintDataOnchange({ constraintDataNameChange: "loadingConnexion", constraintDataValueChange: false }))
            dispatch(setConnexionConstraintDataOnchange({ constraintDataNameChange: "message", constraintDataValueChange: "Verify Your credentials" }))

        }
    });


    const [tokenAPI] = useMutation(graphQL_shema().tokenVerification, {
        context: {
            headers: {
                // Authorization: `Bearer ${token}`
            }
        },
        fetchPolicy: "no-cache",
        variables: { input: { token: `Bearer ${token}` } },
        onCompleted: async (data) => {
            if (data.VerifSecureTokenFoAction.Code === 200) {
                dispatch(setAppSetLogin(token));
            } else {
                dispatch(setAppSetLogout(''));
            }
            setVerificationToken(true)
        },
        onError(err) {
            // console.log('error',err);
            // dispatch(setConnexionConstraintDataOnchange({constraintDataNameChange:"loadingConnexion",constraintDataValueChange:false}))
            // dispatch(setConnexionConstraintDataOnchange({constraintDataNameChange:"message",constraintDataValueChange:"Username Incorrect"}))

        }
    })

    return ({
        Connexion,
        verificationToken,
        tokenAPI
    })

}