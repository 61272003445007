import { useSelector } from 'react-redux';
import SelectList from './step2_module_tabElem';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useState } from 'react';
import '../../css/step2_Client_Conf.css'
import { Label } from "@empreinte/components.label";
import { useMediaQuery } from '@material-ui/core';

const StepTwoModule = () => {

  const step2DataModule = useSelector(state => state.app.step2_data_module);

  const keys = Object.keys(step2DataModule);
  const modules = [];
  keys.forEach(key => {
    const keyParts = key.split('.');
    const keyPart = keyParts[0];

    if (!modules.includes(keyPart)) {
      modules.push(keyPart);
    }
  });

  const module_sousmodule = {};
  modules.forEach(keyPart => {
    const sousModules = keys.filter(key => key.startsWith(keyPart)); 
    
    module_sousmodule[keyPart] = sousModules; 
  });

  const [value, setValue] = useState(0);
  const isWidth1379 = useMediaQuery('(max-width:1397px)');

  const dashboardKeys = Object.keys(step2DataModule).filter(key => key.startsWith('dashboard'));
  const abonnementKeys = Object.keys(step2DataModule).filter(key => key.startsWith('abonnement'));
  const mediaKeys = Object.keys(step2DataModule).filter(key => key.startsWith('media'));
  const profilesKeys = Object.keys(step2DataModule).filter(key => key.startsWith('profiles'));
  const interactivityKeys = Object.keys(step2DataModule).filter(key => key.startsWith('interactivity'));
  const classifyingKeys = Object.keys(step2DataModule).filter(key => key.startsWith('classifying'));
  const configurationKeys = Object.keys(step2DataModule).filter(key => key.startsWith('configuration'));
  const boitierKeys = Object.keys(step2DataModule).filter(key => key.startsWith('boitier'));
  const specificsKeys = Object.keys(step2DataModule).filter(key => key.startsWith('specifics'));
  const statsKeys = Object.keys(step2DataModule).filter(key => key.startsWith('stats'));
  const displayKeys = Object.keys(step2DataModule).filter(key => key.startsWith('display'));
  const skinKeys = Object.keys(step2DataModule).filter(key => key.startsWith('skin'));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className="tabs-box">
      <div className='card-label-container' style={{ marginTop: "30px" }}>
        <Label>Module</Label>
      </div>
      <Tabs
        value={value}
        onChange={handleChange}
        className='module-tabs'
        variant={isWidth1379 ? 'scrollable' : 'standard'}
        scrollButtons={isWidth1379 ? "auto" : false}
      >
        <Tab label="dashboard" wrapped />
        <Tab label="abonnements" wrapped />
        <Tab label="media" wrapped />
        <Tab label="profiles" wrapped />
        <Tab label="interactivity" wrapped />
        <Tab label="classifying" wrapped />
        <Tab label="configuration" wrapped />
        <Tab label="boitier" wrapped />
        <Tab label="specifics" wrapped />
        <Tab label="stats" wrapped />
        <Tab label="display" wrapped />
        <Tab label="skin" wrapped />
      </Tabs>
      {value === 0 && <SelectList name={'dashboard'} keys={dashboardKeys} />}
      {value === 1 && <SelectList name={'abonnement'} keys={abonnementKeys} />}
      {value === 2 && <SelectList name={'media'} keys={mediaKeys} />}
      {value === 3 && <SelectList name={'profiles'} keys={profilesKeys} />}
      {value === 4 && <SelectList name={'interactivity'} keys={interactivityKeys} />}
      {value === 5 && <SelectList name={'classifying'} keys={classifyingKeys} />}
      {value === 6 && <SelectList name={'configuration'} keys={configurationKeys} />}
      {value === 7 && <SelectList name={'boitier'} keys={boitierKeys} />}
      {value === 8 && <SelectList name={'specifics'} keys={specificsKeys} />}
      {value === 9 && <SelectList name={'stats'} keys={statsKeys} />}
      {value === 10 && <SelectList name={'display'} keys={displayKeys} />}
      {value === 11 && <SelectList name={'skin'} keys={skinKeys} />}
    </Box>
  );
}

export default StepTwoModule;