import * as React from 'react';
import Box from '@mui/material/Box';
import '../css/dashbord.css';
import HorizontalNonLinearStepper from './stepper';
import Step1 from './step1_add_client';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Step2 from './step2/step2_Client_Conf';
import Confirmation from './confirmation';
import axios from "axios";
import { useState } from 'react';
import { Card } from '@material-ui/core';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { appAction } from '../redux/actions/appAction';
import CelebrationIcon from '@mui/icons-material/Celebration';
import { Loader } from "@empreinte/components.loader";
import { Strctured_config } from '../redux/reducers/states';
import { GlobalHooks } from '../../utils/Hooks';
import useAxiosInstance from '../../interceptor';
const drawerWidth = 260;



const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Dashbord() {
  const axiosInstance  = useAxiosInstance();
  const Data = useSelector(state => state.app);
  const [message, setMessage] = useState("");
  const steps = ['Ajouter Client', 'Config Client', 'Confirmation'];
  const stepsUpdate = ['Update Client', 'Config Client', 'Confirmation']
  const dispatch = useDispatch();
  let [isOpenLoader, setIsOpenLoader] = useState(false);
  const apiAddClient = process.env.REACT_APP_ADDCLIENT_SF_DATABASE;
  const apiAddClientConf = process.env.REACT_APP_ADDCLIENT_CONF;
  const items = Object.keys(Data.step1_data);
  const structured_filds_config = Strctured_config();
  const step2DataCard = useSelector((state) => state.app.step2_data_card);
  const step1ConfigDNS = Data.step1_config_DNS;
  const  oldFieldsImpactClientConfig = Data.oldFieldsImpactClientConfig ;
  const oldApiDNS = oldFieldsImpactClientConfig.oldApiDNS;
  const oldBoDNS= oldFieldsImpactClientConfig.oldBoDNS;
  const oldFoDNS= oldFieldsImpactClientConfig.oldFoDNS;
  const {cookies} =GlobalHooks();
  const  authToken = cookies.authToken;

  const msgDeleteJson = localStorage.getItem('msg');
  if (msgDeleteJson) {
    const msgDelete = JSON.parse(msgDeleteJson);
    dispatch(appAction('setDeleteClient', { msg: msgDelete }));
    localStorage.removeItem('msg');
  }

  const MultipleDeleteSuccMsg = localStorage.getItem('msgSucc');

  if (MultipleDeleteSuccMsg) {
    const succDelete = JSON.parse(MultipleDeleteSuccMsg);
    dispatch(appAction('setDeleteClient', { msg: succDelete }));
    localStorage.removeItem('msgSucc');
  }

  const MultipleDeleteErrMsg = localStorage.getItem('msgErr');

  if (MultipleDeleteErrMsg) {
    const errDelete = JSON.parse(MultipleDeleteErrMsg);
    dispatch(appAction('setDeleteClient', { msg: errDelete }));
    localStorage.removeItem('msgErr');
  }

  const persistedState = localStorage.getItem('SideBarIndex');
  if (persistedState) {
    const parsedState = JSON.parse(persistedState);
    dispatch(appAction('setStep', { index: parsedState }));
    localStorage.removeItem('SideBarIndex');
  }

  const [skipped, setSkipped] = useState(new Set());

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setMessage("");
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const handleNext = async (event) => {
    let msgErr = '';
    if (Data.activeStep === 0) {
      const requiredItems = [];
      const { 'Nom de client': nomClient, database_password, database_password_webtv } = Data.step1_data;
      const { 'Api DNS': ApiDns, 'Bo DNS' :BoDns, 'Fo DNS' : FoDns } = Data.step1_config_DNS;
      const nomClientTrimmed = nomClient.trim();
      const database_password_trimmed = database_password.trim();
      const database_password_webtv_trimed = database_password_webtv.trim();
      const ApiDns_trimmed = ApiDns.trim(); const BoDns_trimmed = BoDns.trim() ; const FoDns_trimmed = FoDns.trim();
      const lengths = [nomClientTrimmed.length, database_password_trimmed.length, database_password_webtv_trimed.length,ApiDns_trimmed.length,BoDns_trimmed.length,FoDns_trimmed.length];
      const isValidLength = lengths.some(len => len >= 1 && len <= 3);
     
      if (!nomClientTrimmed) {
        requiredItems.push('Nom de client');
      }
      if (!database_password_trimmed) {
        requiredItems.push('database_password');
      }
      if (!database_password_webtv_trimed) {
        requiredItems.push('database_password_webtv');
      }
      if (!ApiDns_trimmed && !items.includes('id')) {
        requiredItems.push('Api DNS');
      }
      if (!BoDns_trimmed && !items.includes('id')) {
        requiredItems.push('Bo DNS');
      }
      if (!FoDns_trimmed && !items.includes('id')) {
        requiredItems.push('Fo DNS');
      }
      if (requiredItems.length > 0) {
        msgErr += requiredItems.join(' , ') + ' manquant !';
      }
      if (isValidLength ) {
        const ClientLengthHandler = " Les champs obligatoire (*) doit contenir au moins 4 caractères !";
        msgErr += ClientLengthHandler;
      }
      if (nomClientTrimmed.includes(' ')) {
        const ClientSpaceHandler = " Le nom de client ne doit pas contenir d'espace !";
        msgErr += ClientSpaceHandler;
      }
      const startsWithNumberRegex = /^[0-9]/; 
      if (startsWithNumberRegex.test(nomClientTrimmed)) {
        const notStartWithNumnber = " Le nom de client ne doit pas commencer avec un nombre !";
        msgErr += notStartWithNumnber;
      }
      const allowedCharsRegex = /^[a-zA-Z0-9\sÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØŒŠÞÙÚÛÜÝŸàáâãäåæçèéêëìíîïðñòóôõöøœšþùúûüýÿ_]+$/;
      if (nomClientTrimmed.length > 0 && !allowedCharsRegex.test(nomClientTrimmed)) {
        const specCaracHandler = " Le nom de client ne doit pas contenir des caractères spéciaux !";
        msgErr += specCaracHandler;
      }
      if (Data.step1_data['Nom de client'].length > 50) {
        const lengthDatbaseHandler = " Ne dépassez pas 50 caractères pour le nom de client !";
        msgErr += lengthDatbaseHandler;
      }
      if (msgErr.length !== 0) {
        msgErr = 'Error : ' + msgErr
        setMessage(msgErr);
      }else if (Data.counter === 0 && !items.includes('id')){
        const newObject = Strctured_config(nomClientTrimmed,ApiDns_trimmed,BoDns_trimmed,FoDns_trimmed);
        const updatedData = { ...Data.step2_data_card, ...newObject };
        dispatch(appAction("step2-config", { formConfig: updatedData }));
        dispatch(appAction('setCounter', { index: Data.counter + 1 }));
      }else if(Data.counter >= 1 && !items.includes('id') && (oldApiDNS !== ''|| oldBoDNS !== '' || oldFoDNS !== '')){
        structured_filds_config.forEach(item => {
          const fieldValue = step2DataCard[item];
         
          if (fieldValue.includes(oldApiDNS) && oldApiDNS !== step1ConfigDNS['Api DNS']) {
        const updatedValue = fieldValue.replace(oldApiDNS, step1ConfigDNS['Api DNS']);
        step2DataCard[item] = updatedValue;
      }else if(fieldValue.includes(oldBoDNS) && oldBoDNS !== step1ConfigDNS['Bo DNS']){
        const updatedValue = fieldValue.replace(oldBoDNS, step1ConfigDNS['Bo DNS']);
        step2DataCard[item] = updatedValue;
      }else if(fieldValue.includes(oldFoDNS) && oldFoDNS !== step1ConfigDNS['Fo DNS']){
        const updatedValue = fieldValue.replace(oldFoDNS, step1ConfigDNS['Fo DNS']);
        step2DataCard[item] = updatedValue;
      }
        });
        dispatch(
          appAction("step2-config", {
            formConfig: step2DataCard,
          })
          )
      }
    }
    if (Data.activeStep === steps.length - 1) {
      setIsOpenLoader(true);
      try {
        const [response1, response2] = await Promise.all([
          axiosInstance.post(apiAddClient, {
            ...Data.step1_data,
            ...Data.step1_config_DNS,
          },{
            headers: {
              Authorization: `Bearer ${authToken}`
            }
          }),
          axiosInstance.post(apiAddClientConf, {
            ...Data.step2_data,
            client_name: Data.step1_data['Nom de client'],
          },{
            headers: {
              Authorization: `Bearer ${authToken}`
            }
          }),
        ]);
        setIsOpenLoader(false)
        if (response1.data.message === 'Le nom de Client existe déja' || response2.data.message === 'Le nom de Client existe déja') {
          msgErr = 'Error : Le nom de Client existe déja !';
          setMessage(msgErr);
        } else if (response1.data.message.includes('Success') && response2.data.message.includes('Success')) {
          setMessage("success")
        }
      } catch (error) {
        console.log(error);
      }


    }



    let newSkipped = skipped;
    if (isStepSkipped(Data.activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(Data.activeStep);
    }

    if (msgErr.includes('Error')) {
      dispatch(appAction('setDashbordStep', { index: 0 }));
    } else {
      dispatch(appAction('setDashbordStep', { index: Data.activeStep + 1 }));
      setSkipped(newSkipped);
    }

  };

  const handleBack = () => {
    dispatch(appAction('setDashbordStep', { index: Data.activeStep - 1 }));
    dispatch(appAction('setOldFields', { name:'oldApiDNS' , value:step1ConfigDNS['Api DNS'].trim}));
    dispatch(appAction('setOldFields', { name:'oldBoDNS' , value:step1ConfigDNS['Bo DNS'].trim}));
    dispatch(appAction('setOldFields', { name:'oldFoDNS' , value:step1ConfigDNS['Fo DNS'].trim}));
  };

  const handleReset = (event) => {
    localStorage.setItem('SideBarIndex', JSON.stringify(1));
    window.location.reload();
  };

  const step_component = () => {
    switch (Data.activeStep) {
      case 0:
        return <Step1 />
      case 1:
        return <Step2 />
      case 2:
        return <Confirmation />
      default:
        return <></>
    }
  }

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` }, marginTop: "45px" }}
      className="main"
    >
      <Snackbar open={message !== "" && message !== "success"} autoHideDuration={4000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleCloseSnackbar} severity={"error"}>
          {message}
        </Alert>
      
      </Snackbar>
      <HorizontalNonLinearStepper steps={items.includes('id') ? stepsUpdate : steps} isStepSkipped={isStepSkipped} />
      {step_component()}
      <Loader
        isOpen={isOpenLoader}
      />
      {Data.activeStep === steps.length && message === "success" ? (
        <div style={{ display: "flex", justifyContent: "center", marginTop: "100px" }}>
          <Card variant="outlined" className='reset'>
            <div style={{ mt: 2, mb: 1 }}>
              <span style={{ marginRight: '13px', marginTop: '9px' }}>Toutes les étapes ont été terminées avec succès.</span><br />
              <div style={{ textAlign: "center" }}>
                <span>Client : <strong>{Data.step1_data['Nom de client']}</strong></span>
                <CelebrationIcon color='success' sx={{ marginLeft: "8px" }} />
              </div>
            </div>
            <Button onClick={handleReset} className='rest-button'>Clients</Button>
          </Card>
        </div>
      ) : (
        <div className={Data.activeStep === 0 ? 'stack-container' : 'stack-container-config'}>
          <Stack direction="row" spacing={2} sx={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              disabled={Data.activeStep === 0}
              onClick={handleBack}
              variant="outlined"
              className='back'
              startIcon={<ChevronLeftIcon />}
            >
              Précédent
            </Button>
            <Button onClick={handleNext} className="next" variant="outlined" endIcon={<NavigateNextIcon />} sx={{ background: Data.activeStep === steps.length - 1 && "#2e6ead !important" }}>
              {Data.activeStep === steps.length - 1 ? 'Enregistrer' : 'Suivant'}
            </Button>
          </Stack>
        </div>
      )}
    </Box>
  );
}

export default Dashbord; 
