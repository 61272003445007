import { useSelector } from 'react-redux';


const ModuleDivison = () => {
  const step2DataModule = useSelector(state => state.app.step2_data_module);

  const dashboardKeys = Object.keys(step2DataModule).filter(key => key.startsWith('dashboard'));
  const abonnementKeys = Object.keys(step2DataModule).filter(key => key.startsWith('abonnement'));
  const mediaKeys = Object.keys(step2DataModule).filter(key => key.startsWith('media'));
  const profilesKeys = Object.keys(step2DataModule).filter(key => key.startsWith('profiles'));
  const interactivityKeys = Object.keys(step2DataModule).filter(key => key.startsWith('interactivity'));
  const classifyingKeys = Object.keys(step2DataModule).filter(key => key.startsWith('classifying'));
  const configurationKeys = Object.keys(step2DataModule).filter(key => key.startsWith('configuration'));
  const boitierKeys = Object.keys(step2DataModule).filter(key => key.startsWith('boitier'));
  const specificsKeys = Object.keys(step2DataModule).filter(key => key.startsWith('specifics'));
  const statsKeys = Object.keys(step2DataModule).filter(key => key.startsWith('stats'));
  const displayKeys = Object.keys(step2DataModule).filter(key => key.startsWith('display'));
  const skinKeys = Object.keys(step2DataModule).filter(key => key.startsWith('skin'));

  const moduleKeys = {
    'dashboard': dashboardKeys,
    'abonnement': abonnementKeys,
    'media': mediaKeys,
    'profiles': profilesKeys,
    'interactivity': interactivityKeys,
    'classifying': classifyingKeys,
    'configuration': configurationKeys,
    'boitier': boitierKeys,
    'specifics': specificsKeys,
    'stats': statsKeys,
    'display': displayKeys,
    'skin': skinKeys
  };

  Object.keys(moduleKeys).forEach((key) => {
    const menu = step2DataModule[key];
    if (menu && menu.disabled) {
      delete moduleKeys[key];
    } else if (menu && menu.selected) {
      moduleKeys[key] = menu.selected;
    }
  });

  const concatenatedKeys = [].concat(...Object.values(moduleKeys));

  const ModuleToDispatch = Object.keys(step2DataModule)
    .filter(key => concatenatedKeys.includes(key))
    .reduce((obj, key) => {
      const { disabled, selected, ...rest } = step2DataModule[key];
      obj[key] = rest;
      return obj;
    }, {});

  return (ModuleToDispatch)
}
export default ModuleDivison;