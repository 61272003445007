import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { GlobalHooks } from '../../utils/Hooks';
import './logout.css';
import { appAction } from '../redux/actions/appAction';
import { useDispatch } from 'react-redux';

function Logout() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const { cookies,removeCookie } = GlobalHooks()
  const handleLogout = () => {

    Object.keys(cookies).forEach((cookieName) => {
        removeCookie(cookieName);
      });
      window.location.reload();
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(appAction('setStep',{index:1}))
  };

 

  return (
    <div>
       <Dialog
        open={open} onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth={true}
        BackdropProps={{ sx: { backgroundColor: 'rgba(0, 0, 0, 0.2) !important'}}}
        >
        <DialogTitle className="dialog-title-logout">Déconnexion</DialogTitle>
        <DialogContent>
          <DialogContentText className="dialog-text">
            Êtes-vous sûr(e) de vouloir vous déconnecter ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className='dialog-btn-logout no-btn-logout'>
            Annuler
          </Button>
          <Button onClick={handleLogout} className='dialog-btn-logout yes-btn-logout'  autoFocus>
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Logout;