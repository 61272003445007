import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { GlobalHooks } from './utils/Hooks';
import ProtectedRoutes from './ProtectedRoutes';

function App() {
  const { cookies } = GlobalHooks();

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/*"
            element={<ProtectedRoutes />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
