import './css/dashbord.css';
import Dashbord from './Dashbord/dashbord';
import SideBar from './sideBar';
import Clients from './Clients/Clients'
import Sql from './Sql/sql';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appAction } from './redux/actions/appAction'
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import { StyledEngineProvider } from '@mui/material/styles';
import { SearchBar } from '@empreinte/components.search-bar';
import Logout from './logout/logout';
const drawerWidth = 260;

function Main() {
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [menuVisivle, setMenuVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('')

  const selectedSideBar = useSelector(state => state.app.selectedSideBar);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  const handleSideBarSelected = (event, index,list_num) => {
    if (list_num === 2) {
      index += 3
    }
    dispatch(appAction('setStep', { index: index }));
  };

//useEffect clean up func
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 775) {
        setMenuVisible(true)
      } else {
        setMenuVisible(false);
      }
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [menuVisivle]);


  const handleSearchBar = (event) => {
    setSearchValue(event.target.value)
  }

  return (
    <Box sx={{ display: 'flex' }} >
      <AppBar
        className="first-line"
        position="fixed"
        sx={{
          backgroundColor: 'white',
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        elevation={0} border={0}
      >
        {menuVisivle ?
          <IconButton
            color="Black"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className='menuButton'

          >
            <MenuIcon />
          </IconButton> : null}

        <div className='search-bar'>
          {selectedSideBar === 1 && <SearchBar placeholder="Search..." setSearchText={handleSearchBar} value={searchValue}></SearchBar>}
        </div>

      </AppBar>
      <StyledEngineProvider injectFirst>
        <SideBar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} selectedIndex={selectedSideBar} handleListItemClick={handleSideBarSelected} />
      </StyledEngineProvider>

      {selectedSideBar === 0 && <Dashbord />}
      {selectedSideBar === 1 && <Clients searchValue={searchValue} />}
      {selectedSideBar === 2 && <Sql />}
      {selectedSideBar === 3 && <div>Hello Help</div>}
      {selectedSideBar === 4 && <Logout />}
    </Box>
  );
}

export default Main; 
