import { useEffect } from 'react';
import axios from 'axios';
import { GlobalHooks } from './utils/Hooks';

const useAxiosInstance = () => {
  const { cookies, removeCookie } = GlobalHooks();
  const axiosInstance = axios.create();

  useEffect(() => {
    const interceptor = axiosInstance.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.response && error.response.status === 401) {
          Object.keys(cookies).forEach(cookieName => {
            removeCookie(cookieName);
          });
          window.location.href = '/';
        }
        return Promise.reject(error);
      }
    );
    return () => {
      axiosInstance.interceptors.response.eject(interceptor);
    };
  }, [axiosInstance, cookies, removeCookie]); 

  return axiosInstance;
};

export default useAxiosInstance;
