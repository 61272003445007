import {useDispatch,useSelector} from "react-redux";
import {setConnexionOnchange,setPersistActions,setConnexionConstraintDataOnchange} from '../../redux/actions/connexionActions/connexionAction'
import { useNavigate } from 'react-router-dom';
import {GraphQLFetchData} from "./GraphQLFetchData";

export const Hooks = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const rememberMe = useSelector((state) => state.connexion.userConstantsData);
    const {Connexion} = GraphQLFetchData();
    const connexion =() => {
        try
        { Connexion()}
        catch(error){
            console.log(error)
        };
        
    }

   const connexionOnChange = async (event) => {
    dispatch(setConnexionOnchange({
        ConnexionNameChange: event.target.name,
        ConnexionValueChange: event.target.value
    }));
  }

  const connexionOnChangeButton = (event) =>{
    dispatch(setPersistActions({persistNameChange:"storageIsRememberMe",persistValueChange:event.target.checked}))
}

const toForgotPassword = () =>{
    navigate("/forgot-password")
}


const onFinish = (values) => {
    
    dispatch(setConnexionConstraintDataOnchange({constraintDataNameChange:"loadingConnexion",constraintDataValueChange:true}))
    connexion()
};


  return {
    connexionOnChange,
    connexionOnChangeButton,
    toForgotPassword,
    onFinish
  };
};