import '../css/sql.css';
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from '@empreinte/components.button';
import { Label } from "@empreinte/components.label";
import { Loader } from "@empreinte/components.loader";
import Textarea from '@mui/joy/Textarea';
import { Card, CardContent } from "@material-ui/core";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { GlobalHooks } from '../../utils/Hooks';
import { useRef } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import useAxiosInstance from '../../interceptor';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Sql = () => {
  const  axiosInstance  = useAxiosInstance();
  const apiUpdateSchema = process.env.REACT_APP_SCHEMA_UPDATE;

  const [idSelected, setIdSelected] = useState([]);
  const [sqlText, setSqlText] = useState("");
  let [isOpenLoader, setIsOpenLoader] = useState(false);
  const [msg, setMsg] = useState("");
  const {cookies} =GlobalHooks();
  const  authToken = cookies.authToken;

  const handleTextArea = (event) => {
    setSqlText(event.target.value);
  };

  const [result, setResult] = useState(null);
  const [worker, setWorker] = useState(null);

  useEffect(() => {
    // Create a new web worker
    const myWorker = new Worker('worker.js');

    // Set up event listener for messages from the worker
    myWorker.onmessage = function (event) {
      console.log('Received result from worker:', event.data);
      setResult(event.data);
    };

    // Save the worker instance to state
    setWorker(myWorker);

    // Clean up the worker when the component unmounts
    return () => {
      myWorker.terminate();
    };
  }, []); 



  const handleFileUpload = async (event) => {
    const file = event.target.files[0];

    if (file && worker) {
      setIsOpenLoader(true); 

      const chunkSize = 10240 * 10240; 
      let offset = 0;

      worker.onmessage = function (event) {
        console.log('Received result from worker');

        setSqlText((prevSqlText) => prevSqlText + event.data);

        processNextChunk();
      };

      worker.onerror = function (error) {
        console.error('Error in worker:', error.message);
        setIsOpenLoader(false); 
      };

      const processNextChunk = () => {
        const chunk = file.slice(offset, offset + chunkSize);
        offset += chunkSize;

        if (chunk.size > 0) {
          worker.postMessage(chunk);
        } else {
          worker.terminate();
          setIsOpenLoader(false); 
        }
      };

      processNextChunk();
    }
  };


  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handleClick = async (event) => {
    try {
      if (sqlText.trim() === "") {
        setMsg("Error: Text area is empty!");
        return;
      }
  
      setIsOpenLoader(true);
  
      // Split the SQL text into chunks (adjust chunk size as needed)
      const chunkSize = 1024 * 1024; // 1 KB chunks (adjust as needed)
      const chunks = [];
      for (let i = 0; i < sqlText.length; i += chunkSize) {
        chunks.push(sqlText.substring(i, i + chunkSize));
      }
  
      // Iterate over chunks and send them sequentially
      for (const chunk of chunks) {
        // Create a FormData object
        const formData = new FormData();
        formData.append("query", chunk);
  
        // Send the chunk to the server
        try {
          const response = await axiosInstance.put(apiUpdateSchema, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${authToken}`,
            },
          });
          const status = response.status;
          const message = response.data.message;
  
          if (status === 200) {
            setMsg(`Success: ${message}`);
          } else {
            setMsg(`Error: ${status} - ${message}`);
          }
        } catch (error) {
          console.error("Error:", error);
  
          if (error.response) {
            const err = error.response.data.message;
            setMsg(`Error: ${err}`);
          } else if (error.request) {
            setMsg("Error: No response received from the server");
          } else {
            setMsg("Error: Something went wrong with the request");
          }
        }
      }
  
      setSqlText("");
    } finally {
      setIsOpenLoader(false);
    }
  };
  
  


  const handleCloseSnackbar = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMsg("");
  };

  return (
    <div className="sql-container" >
      <Snackbar open={msg !== "" && msg.includes("Error")} autoHideDuration={4000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert sx={{ width: '100%', backgroundColor: '#D32F2F !important', color: '#fff !important' }}
          onClose={handleCloseSnackbar}
          severity={"error"}
        >
          {msg}
        </Alert>
      </Snackbar>
      <Loader
        isOpen={isOpenLoader}
      />
      <div className='label-sql'>
        <Label>SQL</Label>
      </div>
      <Card className="sql-card">
        <CardContent className="sql-cardContent">
          <div className="sql-box" style={{ maxHeight: '500px', overflowY: 'auto' }}> 
            <Label className="sql-label">SQL</Label>
            <Textarea  name="Outlined" value={sqlText} placeholder="Type in here…" variant="outlined" minRows={8} onChange={handleTextArea} className="sql-textarea" />
            <input type="file" accept=".sql" style={{ display: 'none' }} onChange={handleFileUpload} id="file-input" ref={fileInputRef} />
          </div>
         
          <div className="button-container">
          
            <Button
              title="Envoyer"
              className="sql-btn"
              variant="contained"
              component="label"
              startIcon={<CheckCircleOutlineIcon sx={{ marginRight: '8px' }} />}
              onClick={handleClick}
            >
            </Button> 
            <Button
               htmlFor="file-input"
               className='upload-btn'
               title='Upload SQL'
               variant="outlined"
               color="success"
               component="span"
               startIcon={<CloudUploadIcon sx={{  color:'black', marginRight : '8px'}} />}
               onClick={handleButtonClick}  
               >               
           </Button>
          </div>
        </CardContent>
      </Card>
      <Snackbar open={msg !== "" && !msg.includes("Error")} autoHideDuration={4000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert sx={{ width: '100%', backgroundColor: 'rgb(46, 125, 50) !important', color: '#fff !important' }}
          onClose={handleCloseSnackbar}
          severity={"success"}
        >
          {msg}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default Sql;