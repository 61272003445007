import gql from "graphql-tag";


export const graphQL_shema = ()=> {

    const forgetPassword = gql`
       mutation($input:RequestResetPasswordInput!) {
        requestRestePassword(input:$input) {
        status
        Message
       }
     }
    `;
    return({
        forgetPassword,
    })
}
