import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Card, CardContent } from "@material-ui/core";
import { Button, OutlinedInput, IconButton, InputAdornment } from "@mui/material";
import { LockOutlined } from "@material-ui/icons";
// import {Hooks} from "../utils/hooks";

export const ResetPassword = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordTwo, setShowPasswordTwo] = useState(false);
    const [verif, setVerif] = useState(false);
    // const {values,resetPasswordOnChange}=Hooks();

    const handleClickShowPassword = () => {
        setShowPassword((showPassword) => !showPassword);
    };


    const handleClickShowPasswordTwo = () => {
        setShowPasswordTwo((showPasswordTwo) => !showPasswordTwo);
    };

    const isValidPassword = (password) => {

        return /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&()^_!"#$%&'*+£,-./:;{}<>=|~?·•¯‾|¦‌‍†‡§¶©®™&@/\◊♠♣♥♦←↑→↓↔áÁâÂàÀåÅãÃäÄæÆçÇéÉêÊèÈëËíÍîÎìÌïÏñÑóÓôÔòÒøØõÕöÖœŒšŠßðÐÞúÚûÛùÙüÜýÝÿŸ¤€$¢£¥ƒαΑβΒγΓδΔεΕζΖηΗθΘιΙκΚλΛμΜνΝξΞοΟπΠρΡσςΣτΤυΥφΦχΧψΨωΩ°µ < >≤≥=≈≠≡±−+×÷⁄%‰¼½¾¹²³ºªƒ″∂∏∑√∞¬∩∫])[A-Za-z\d@$!%*?&()^_!"#$%&'*+£,-./:;{}<>=|~?·•¯‾_ |¦‌‍†‡§¶©®™&@/\◊♠♣♥♦←↑→↓↔áÁâÂàÀåÅãÃäÄæÆçÇéÉêÊèÈëËíÍîÎìÌïÏñÑóÓôÔòÒøØõÕöÖœŒšŠßðÐÞúÚûÛùÙüÜýÝÿŸ¤€$¢£¥ƒαΑβΒγΓδΔεΕζΖηΗθΘιΙκΚλΛμΜνΝξΞοΟπΠρΡσςΣτΤυΥφΦχΧψΨωΩ°µ < >≤≥=≈≠≡±−+×÷⁄%‰¼½¾¹²³ºªƒ″∂∏∑√∞¬∩∫]{8,}$/.test(password)
    }
    const isConfirmPassword = (password) => {

        // return values.resetPassword.newPassword===password
    }
    return (
        <Card className="card-reset-password">
            <CardContent className="card-content-reset-password">
                <label className="reset-password-title">
                    Réinitialisation du mot de passe
                </label>
                <label className="reset-password-sous-title">
                Nouveau mot de passe
                </label>
                <form className='reset-password-form'>
                    <label className="reset-password-input-label" > Entrer nouveau mot de passe</label>
                    <OutlinedInput
                        placeholder="New Password"
                        variant="outlined"
                        name="newPassword"
                        // value={userData.Password}
                        className="input-rest-password"
                        // onChange={resetPasswordOnChange}
                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        startAdornment={<LockOutlined className="icon-reset-password" />}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={(event) => event.preventDefault()}
                                    onMouseUp={(event) => event.preventDefault()}
                                    edge="end"
                                >
                                    {showPassword ? (
                                        <Visibility className="icon-reset-visibility"/>
                                    ) : (
                                        <VisibilityOff className="icon-reset-visibility"/>
                                    )}
                                </IconButton>
                            </InputAdornment>
                        }
                        fullWidth
                        inputProps={{
      minLength: 8,
      pattern: "^(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&()^_!\"#$%&'*+£,-./:;{}<>=|~?·•¯‾|¦‌‍†‡§¶©®™&@/\\◊♠♣♥♦←↑→↓↔áÁâÂàÀåÅãÃäÄæÆçÇéÉêÊèÈëËíÍîÎìÌïÏñÑóÓôÔòÒøØõÕöÖœŒšŠßðÐÞúÚûÛùÙüÜýÝÿŸ¤€$¢£¥ƒαΑβΒγΓδΔεΕζΖηΗθΘιΙκΚλΛμΜνΝξΞοΟπΠρΡσςΣτΤυΥφΦχΧψΨωΩ°µ < >≤≥=≈≠≡±−+×÷⁄%‰¼½¾¹²³ºªƒ″∂∏∑√∞¬∩∫])[A-Za-z\\d@$!%*?&()^_!\"#$%&'*+£,-./:;{}<>=|~?·•¯‾_ |¦‌‍†‡§¶©®™&@/\\◊♠♣♥♦←↑→↓↔áÁâÂàÀåÅãÃäÄæÆçÇéÉêÊèÈëËíÍîÎìÌïÏñÑóÓôÔòÒøØõÕöÖœŒšŠßðÐÞúÚûÛùÙüÜýÝÿŸ¤€$¢£¥ƒαΑβΒγΓδΔεΕζΖηΗθΘιΙκΚλΛμΜνΝξΞοΟπΠρΡσςΣτΤυΥφΦχΧψΨωΩ°µ < >≤≥=≈≠≡±−+×÷⁄%‰¼½¾¹²³ºªƒ″∂∏∑√∞¬∩∫]{8,}$"
    }}
                    />

                    <label className="reset-password-input-label" >Confirmer nouveau mot de passe</label>
                    <OutlinedInput
                        placeholder="Confirm New Pass"
                        variant="outlined"
                        name="confirmPassword"
                        // value={userData.Password}
                        className="input-rest-password"
                          // onChange={resetPasswordOnChange}
                        id="outlined-adornment-password"
                        type={showPasswordTwo ? 'text' : 'password'}
                        startAdornment={<LockOutlined className="icon-reset-password" />}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPasswordTwo}
                                    onMouseDown={(event) => event.preventDefault()}
                                    onMouseUp={(event) => event.preventDefault()}
                                    edge="end"
                                >
                                    {showPasswordTwo ? (
                                        <Visibility className="icon-reset-visibility"/>
                                    ) : (
                                        <VisibilityOff className="icon-reset-visibility"/>
                                    )}
                                </IconButton>
                            </InputAdornment>
                        }
                        fullWidth
                    />
                    <Button className="btn-reset-form-password">
                        Reset Password
                    </Button>
                </form>
            </CardContent>
        </Card>
    )
}
