import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { GlobalHooks } from './utils/Hooks';
import Main from './components/main';
import Connexion from './components/connexion/Connexion';
import { ForgetPassword } from './components/forgotPassword/forgetPassword';
import { ResetPassword } from './components/resetPassword/resetPassword';

const useAuth = () => {
  const { cookies } = GlobalHooks();
  return cookies.validated; 
};

const ProtectedRoutes = () => {
  const isAuthenticated = useAuth();

  return (
    <Routes>
      <Route
        path="/"
        element={isAuthenticated ? <Navigate to="/main" /> : <Connexion />}
      />
      <Route
        path="/forgot-password"
        element={!isAuthenticated ? <Navigate to="/main" /> : <ForgetPassword />}
      />
      <Route
        path="/reset-password"
        element={!isAuthenticated ? <Navigate to="/main" /> : <ResetPassword />}
      />
      <Route
        path="/main"
        element={isAuthenticated ? <Main /> : <Navigate to="/" />}
      />
    </Routes>
  );
};

export default ProtectedRoutes;
