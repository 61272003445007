import { combineReducers } from "redux";
import appReducer from "./appReducer";
import connexionReducer from "./connexionReducer"; 
import  ForgetPasswordReducer from "./forgetPasswordReducer";
import  ResetPasswordReducer from "./resetPasswordReducer";

const rootReducer = combineReducers({
    app: appReducer,
    connexion: connexionReducer,
    forgetPasswordReducer: ForgetPasswordReducer,
    resetPasswordReducer:ResetPasswordReducer,
  });
  
  export default rootReducer;