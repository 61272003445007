import React from 'react';
import {Form} from 'antd'
import '../resetPassword.css'
import {Hooks} from "../utils/hooks";
import {RecoveryPassword} from "./recoveryPassword";
import {ResetPassword} from "./resetPassword";
import {ErrorResetPassword} from "./errorResetPassword";
import LogoEmpreinte  from "../../../assets/LogoEmpreinte.webp";


export const FormResetPassword =()=>{
    // const [form] = Form.useForm();
    // const{handleSubmit,values}=Hooks()

    return(
        // <Form
        //     form={form}
        //     layout="horizontal"
        //     name="product-form"
        //     onFinish={handleSubmit}
        // >
        <div className='Connection-form-reset-pass'>
        <div className="image-container-login">
                <img src={LogoEmpreinte} alt="img-contaoner-login" />
            </div>
            <ResetPassword/>
        </div>
            // {values.constraintData.passwordSent ?
            //     <RecoveryPassword/>
            //     : values.constraintData.tokenInvalid || values.constraintData.tokenExpired ?
            //         <ErrorResetPassword/> :
            //         <ResetPassword/>
            // }
        // </Form>
    )
}
