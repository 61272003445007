const step1_data = {
  "Nom de client": "",
  "database_password": "",
  "database_host": "",
  "database_port": "",
  "database_driver": "",
  "database_webtv": "",
  "database_password_webtv": "",
  "database_host_webtv": "",
  "database_port_webtv": "",
  "database_driver_webtv": "",
  "piper_upload_endpoint": "",
  "piper_transcode_endpoint": "",
  "piper_metadata_endpoint": "",
  "tus_cloudflare_endpoint": "",
  "use_send_grid": ""
}

const step2_module = {
  dashboard: {
    disabled: false,
    selected: ['dashboard'],
    icon: "icon-tile-four",
    state: "app.dashboard",
    title: "Dashboard",
    translate: "PAGESMENUSTITLES.dashboard_menuTitle",
    weight: 1,
    permission: "dashboard_page",
  },
  abonnement: {
    disabled: false,
    selected: ['abonnement'],
    title: "abonnement",
    translate: "PAGESMENUSTITLES.abonnement_menuTitle",
    //group : true,
    icon: "icon-clipboard-account",
    weight: 2,
  },
  "abonnement.group": {
    title: "group",
    translate: "PAGESMENUSTITLES.abonnement_group_menuTitle",
    icon: "icon-account-multiple-plus",
    state: "app.abonnement_group_list",
    permission: "subscription_group_list_dtable",
  },
  "abonnement.user": {
    icon: "icon-account",
    permission: "subscription_user_list_dtable",
    state: "app.abonnement_user_list",
    title: "user",
    translate: "PAGESMENUSTITLES.abonnement_user_menuTitle",
    weight: 1,
  },
  media: {
    disabled: false,
    selected: ['media'],
    icon: "icon-video",
    title: "Media",
    translate: "PAGESMENUSTITLES.media_menuTitle",
    weight: 4,
  },
  "media.video": {
    icon: "icon-play-box-outline",
    permission: "media_video_list_dtable_videos_ongoing",
    state: "app.media_video_list({type: 'ongoing'})",
    stateList: "app.media_video_list",
    title: "Videos",
    translate: "PAGESMENUSTITLES.media_video_menuTitle",
    weight: 1,
  },
  "media.lives": {
    icon: "icon-live-custom",
    permission: "lives_dtable_lives_endless",
    state: "app.lives_list_ongoing",
    stateList: "app.lives_list_ongoing",
    title: "Lives",
    translate: "PAGESMENUSTITLES.lives_menuTitle",
    weight: 3,
  },
  "media.playlist": {
    icon: "icon-format-indent-increase",
    permission: "playlist_list_dtable",
    state: "app.playlist_list.simple",
    title: "Playlist",
    translate: "PAGESMENUSTITLES.playlist_menuTitle",
    weight: 2,
  },
  "media.event": {
    icon: "icon-calendar-blank",
    permission: "event_list_dtable_events_ongoing",
    state: "app.event_list_ongoing",
    title: "Live event",
    translate: "PAGESMENUSTITLES.event_menuTitle",
    weight: 4,
  },
  "media.podcast": {
    icon: "icon-microphone-outline",
    permission: "podcast_list_dtable",
    state: "app.podcast_list",
    title: "Podcast",
    translate: "PAGESMENUSTITLES.podcast_menuTitle",
    weight: 5,
  },
  "media.image": {
    icon: "icon-image-area",
    permission: "photoprism_list_menu",
    state: "app.image",
    title: "Image",
    translate: "PAGESMENUSTITLES.images_menuTitle",
    weight: 6,
  },
  "media.scorm": {
    icon: "icon-folder",
    permission: "scorm_scorm_no_api",
    state: "app.scorm",
    title: "Scorm",
    translate: "PAGESMENUSTITLES.scorm_menuTitle",
    weight: 7,
  },
  "media.chutier": {
    icon: "icon-folder",
    permission: "chutier_list_dtable",
    state: "app.chutier_list",
    stateList: "app.chutier.list",
    title: "Chutier",
    translate: "PAGESMENUSTITLES.chutier_menuTitle",
    weight: 1,
  },
  profiles: {
    disabled: false,
    selected: ['profiles'],
    icon: "icon icon-account-multiple",
    title: "Profiles",
    translate: "PAGESMENUSTITLES.profiles_menuTitle",
    weight: 3,
  },
  "profiles.user": {
    icon: "icon-account",
    permission: "profiles_user_list_dtable",
    state: "app.profiles_user_list",
    title: "Users",
    translate: "PAGESMENUSTITLES.profiles_user_menuTitle",
    weight: 1,
  },
  "profiles.group": {
    icon: "icon-account-box",
    permission: "profiles_group_list_dtable",
    state: "app.profiles_group_list",
    title: "Groups",
    translate: "PAGESMENUSTITLES.profiles_group_menuTitle",
    weight: 1,
  },
  "profiles.ip": {
    title: "zone",
    translate: "PAGESMENUSTITLES.profiles_ip_menuTitle",
    icon: "icon-security",
    state: "app.profiles_ip_list",
    permission: "profiles_ip_list_dtable",
  },

  interactivity: {
    disabled: false,
    selected: ['interactivity'],
    icon: "icon-newspaper",
    title: "Interactivity",
    translate: "PAGESMENUSTITLES.interactivity_menuTitle",
    weight: 5,
  },
  "interactivity.sondage": {
    icon: "icon-poll",
    permission: "sondage_list_dtable",
    state: "app.sondage_list",
    title: "sondage",
    translate: "PAGESMENUSTITLES.sondage_menuTitle",
    weight: 11,
  },
  classifying: {
    disabled: false,
    selected: ['classifying'],
    icon: "icon-classement-custom",
    title: "classifying",
    translate: "PAGESMENUSTITLES.classifying_menuTitle",
    weight: 6,
  },
  "classifying.theme": {
    icon: "icon-file-image-box",
    permission: "parameters_theme_list_dtable",
    state: "app.parameters_theme_list",
    title: "Themes",
    translate: "PAGESMENUSTITLES.parameters_theme_menuTitle",
    weight: 1,
  },
  "classifying.canal": {
    icon: "icon-plus-network",
    permission: "parameters_canal_list_dtable",
    state: "app.parameters_canal_list",
    title: "Channels",
    translate: "PAGESMENUSTITLES.parameters_canal_menuTitle",
    weight: 5,
  },
  configuration: {
    disabled: false,
    selected: ['configuration'],
    icon: "icon-cog",
    title: "configuration",
    translate: "PAGESMENUSTITLES.configuration_menuTitle",
    weight: 7,
  },
  "configuration.home": {
    title: "home",
    translate: "PAGESMENUSTITLES.media_home_menuTitle",
    icon: "icon-home",
    weight: 5,
    // group : true,
  },
  "configuration.home.conf": {
    icon: "icon-file-presentation-box",
    state: "app.media_home_conf",
    title: "Configuration",
    translate: "PAGESMENUSTITLES.media_home_conf_menuTitle",
    weight: 1,
  },
  "configuration.home.list": {
    icon: "icon-view-list",
    permission: "media_home_get_home_conf_videos",
    state: "app.media_home_list",
    title: "List",
    translate: "PAGESMENUSTITLES.media_home_list_menuTitle",
    weight: 1,
  },
  "configuration.home.push": {
    icon: "icon-arrange-send-backward",
    permission: "media_home_post_disable_push_video",
    state: "app.media_home_push",
    title: "Push",
    translate: "PAGESMENUSTITLES.media_home_push_menuTitle",
    weight: 1,
  },
  "configuration.configuration_mooc": {
    icon: "icon-book-open",
    permission: "mooc_list_dtable_params",
    state: "app.configuration_mooc_list",
    title: "configuration_mooc",
    translate: "PAGESMENUSTITLES.configuration_mooc",
    weight: 1,
  },
  "configuration.server": {
    icon: "icon-server",
    permission: "configuration_server_list_dtable",
    state: "app.configuration_server_list",
    title: "servers",
    translate: "PAGESMENUSTITLES.configuration_server_menuTitle",
    uisref: "app.configuration_server_list",
    weight: 1,
  },
  "configuration.presets": {
    icon: "icon-cog",
    permission: "configuration_presets_list_dtable",
    state: "app.configuration_presets_list",
    title: "presets",
    translate: "PAGESMENUSTITLES.configuration_presets_menuTitle",
    weight: 1,
  },
  "configuration.referrers": {
    icon: "icon-sitemap",
    permission: "referrers_list_dtable",
    state: "app.referrers_list",
    title: "Referrers",
    translate: "PAGESMENUSTITLES.configuration_referrers_menuTitle",
    weight: 1,
  },
  "configuration.client": {
    title: "param",
    translate: "PAGESMENUSTITLES.param_menuTitle",
    icon: "icon-account-check",
    state: "app.param_list",
    permission: "param_list_dtable",
  },
  "configuration.news": {
    icon: "icon-apps",
    permission: "configuration_newss_list_dtable_news",
    state: "app.configuration_news_list",
    title: "News",
    translate: "PAGESMENUSTITLES.configuration_news_menuTitle",
    uisref: "app.configuration_news_list",
    weight: 1,
  },
  "configuration.player_parameters": {
    icon: "icon-video-switch",
    permission: "player_parameters_list_no_api",
    state: "app.player_parameters_list",
    title: "player_parameters",
    translate: "PAGESMENUSTITLES.player_parameters",
    weight: 1,
  },
  "configuration.metaData": {
    icon: "icon-meta-custom",
    permission: "metaData_list_dtable",
    state: "app.metaData_list",
    stateList: "app.metaData.list",
    title: "metaData",
    translate: "PAGESMENUSTITLES.metaData_menuTitle",
    weight: 1,
  },
  boitier: {
    disabled: false,
    selected: ['boitier'],
    icon: "icon-houzz-box",
    title: "boitier",
    translate: "PAGESMENUSTITLES.boitier_menuTitle",
    weight: 8,
  },
  "boitier.rmboxgroup": {
    icon: "icon-group",
    permission: "configuration_rmboxgroup_list_dtable",
    state: "app.configuration_rmboxgroup_list",
    title: "RmBox groups",
    translate: "PAGESMENUSTITLES.configuration_rmboxgroup_menuTitle",
    weight: 1,
  },
  "boitier.rmbox": {
    icon: "icon-rmbox-custom",
    permission: "configuration_rmbox_list_dtable",
    state: "app.configuration_rmbox_list",
    title: "rmbox",
    translate: "PAGESMENUSTITLES.configuration_rmbox_menuTitle",
    weight: 1,
  },
  "boitier.zone": {
    icon: "icon-map-marker-radius",
    permission: "configuration_zone_list_dtable",
    state: "app.configuration_zone_list",
    title: "zone",
    translate: "PAGESMENUSTITLES.configuration_zone_menuTitle",
    uisref: "app.configuration_zone_list",
    weight: 1,
  },
  "boitier.autoriseip": {
    icon: "icon-security",
    permission: "boitier_autoriseip_list_dtable",
    state: "app.boitier_autoriseip_list",
    title: "Autorise Ip",
    translate: "PAGESMENUSTITLES.rmbox_autorise_ip_menuTitle",
    uisref: "app.boitier_autoriseip_list",
    weight: 1,
  },
  specifics: {
    disabled: false,
    selected: ['specifics'],
    icon: "icon-film",
    title: "Specifics",
    translate: "PAGESMENUSTITLES.specifics_menuTitle",
    weight: 9,
  },
  "specifics.direction": {
    icon: "icon-apps",
    permission: "specifics_directions_list_dtable_direction",
    state: "app.specifics_direction_list",
    title: "Direction",
    translate: "PAGESMENUSTITLES.specifics_direction_menuTitle",
    weight: 1,
  },
  "specifics.iptv": {
    icon: "icon-cast",
    permission: "specifics_iptv_list_dtable",
    state: "app.specifics_iptv_list",
    title: "Iptvs",
    translate: "PAGESMENUSTITLES.specifics_iptv_menuTitle",
    weight: 1,
  },
  "specifics.station": {
    icon: "icon-play-circle",
    permission: "specifics_stations_list_dtable",
    state: "app.specifics_station_list",
    title: "Stations",
    translate: "PAGESMENUSTITLES.specifics_station_menuTitle",
    uisref: "app.specifics_station_list",
    weight: 1,
  },
  stats: {
    disabled: false,
    selected: ['stats'],
    icon: "icon-chart-bar",
    title: "Statistique",
    translate: "PAGESMENUSTITLES.stats",
    weight: 10,
  },
  "stats.dashboard": {
    icon: "icon-view-list",
    permission: "stats_dashboard_dashboard_no_api_route",
    state: "app.stats_dashboard",
    title: "Dashboard",
    translate: "PAGESMENUSTITLES.stats_dashboard",
    weight: 1,
  },
  "stats.vod": {
    icon: "icon-video",
    permission: "stats_vod_all",
    state: "app.stats_vods.audience",
    title: "vod",
    translate: "PAGESMENUSTITLES.stats_videos",
    weight: 1,
  },
  "stats.live": {
    icon: "icon-archive",
    permission: "stats_live_all",
    state: "app.stats_lives.audience",
    title: "live",
    weight: 1,
    translate: "PAGESMENUSTITLES.stats_live_pagetitle",
  },
  "stats.acces": {
    icon: "icon-account-key",
    permission: "stats_acces_list_acces",
    state: "app.stats_acces",
    title: "Statistiques d'accès",
    translate: "PAGESMENUSTITLES.stats_access",
    uisref: "app.stats_acces",
    weight: 1,
  },
  "stats.consumptions": {
    icon: "icon-chart-areaspline",
    permission: "stats_bande_passante_post_bande_passante",
    state: "app.stats_bande_passante",
    title: "consumption",
    translate: "PAGESMENUSTITLES.stats_consumption_pagetitle",
    uisref: "app.stats_bande_passante",
    weight: 1,
  },
  "stats.stockage": {
    icon: "icon-server-network",
    permission: "stats_storage_post_storage",
    state: "app.stats_storage",
    title: "stockage",
    translate: "PAGESMENUSTITLES.stats_storage_pagetitle",
    weight: 1,
  },
  "stats.mooc": {
    icon: "icon-server-network",
    permission: "stats_mooc_course_no_api",
    state: "app.stats_mooc.course",
    title: "mooc",
    translate: "PAGESMENUSTITLES.stats_mooc_pagetitle",
    weight: 1,
  },
  "stats.list_videos": {
    icon: "icon-play-box-outline",
    permission: "stats_list_videos_list_dtable_videos_stats",
    state: "app.stats_list_videos",
    title: "list_videos",
    translate: "PAGESMENUSTITLES.stats_list_videos_pagetitle",
    weight: 1,
  },
  display: {
    disabled: false,
    selected: ['display'],
    icon: "icon-cube",
    title: "Display",
    translate: "PAGESMENUSTITLES.display_menuTitle",
    weight: 11,
  },
  "display.template_manager": {
    icon: "icon-calendar-clock",
    permission: "display_template_list_dtable",
    state: "app.template_manager_list",
    title: "Tempate Editor",
    translate: "PAGESMENUSTITLES.display_programs_menuTitle",
    weight: 3,
  },
  display_new: {
    icon: "icon-cube",
    title: "Display New ",
    translate:
      'Display <img src="assets/icons/new.png" class="ng-scope" style="padding-top: 6px;">',
    weight: 12,
  },
  "display_new.new_template_manager": {
    icon: "icon-calendar-clock",
    permission: "display_new_template_list_dtable",
    state: "app.new_template_manager_list_all",
    title: "Tempate Editor",
    translate: "PAGESMENUSTITLES.display_programs_menuTitle",
    weight: 3,
  },
  "display_new.new_template_segment": {
    icon: "icon-view-carousel",
    permission: "display_new_template_list_dtable",
    state: "app.new_template_segment_list",
    title: "Tempate Editor",
    translate: "PAGESMENUSTITLES.display_template_segment_menuTitle",
    weight: 5,
  },
  "display_new.schedule": {
    title: "Users",
    translate: "PAGESMENUSTITLES.display_schedule_menuTitle",
    permission: "display_new_schedule_list_dtable",
    icon: "icon-calendar-text",
    state: "app.display_schedule_list_new",
    weight: 4,
  },
  "display.schedule": {
    title: "Users",
    translate: "PAGESMENUSTITLES.display_schedule_menuTitle",
    permission: "display_schedule_list_dtable",
    icon: "icon-calendar-text",
    state: "app.display_schedule_list",
    weight: 4,
  },
  skin: {
    disabled: false,
    selected: ['skin'],
    icon: "icon-palette",
    title: "Skin ",
    translate: "Skin",
    weight: 13,
  },
  "skin.player": {
    title: "Player",
    translate: "Player",
    permission: "skin_skin_no_api",
    icon: "icon-play-circle",
    state: "app.skin",
    weight: 1,
  }
}

const step2_config = {
  CLIENT_NAME: "test",
  isNew_Stats: true,
  isNewAccessStatistics : true,
  FO_STATS_URL: "",
  PODCAST_INGRESS: "",
  // Module Configuration Server
isNew_Configuration_Server: true,
URL_Configuration_Server : "",
// Module Profile IP
isNew_Profile_IP : true,
URL_Profile_IP : "",
URL_Profile_User: "",
isNew_User: true,
  // Module stats:
  API_HOST_STATS: "",
  STAT_CLIENT_ID: "wDCuNSkYGUWRyF9sYnHBDA",
  //Module chutier
  URL_Chutier: "",
  //Module classement
  URL_Classement: "",
  isNew_class_theme: true,
  isNew_class_canal: true,
  //Module metaData
  URL_metaData: "",
  //Module media webservice
  URL_Media: "",
  isNew_Media_Vod: true,
  isNew_Media_Podcast: true,
  isNewGroup:true,
isNewClient: true,
URL_Configuration_Client: "",
URL_Profile_Group: "",
// Module playlist
isNewPlaylist: true,
  // Module piper-transcoding
  PIPER_ENDPOINT: "",
  SERVERLESS_PIPER_ENDPOINT: "",
  API_HOST_UPLOAD: "",
  BUCKET_URL_THUMBNAIL: "",
  PODCAST_S3_URL: "",
  API_HOST_DOWNLOAD: "",
  // BO + FO
  API_HOST_GLOBAL: "",
  API_HOST_DAM: "",
  API_TOKEN: "eyJhbGciOiJSUzI1NiJ9.eyJyb2xlcyI6WyJST0xFX0ZPIiwiUk9MRV9VU0VSIl0sInVzZXJuYW1lIjoiX19mbyIsImlhdCI6MTUyMTcwNjg1MiwiZXhwIjo3ODI4OTA2ODUyfQ.PrH1m-xPhLuisv9MrBPYPbE3-mAcwnDvjrXkPrc0SluUtyL49a15BssCegqiGDl4X_V9w4tRH3KJbOhbcML7vf704yO3cZErX_U2Y68RbSj1hxxKEfSwBh0NFQwGbt1rhXMJTRWwLWyszvmtUsSmgTrOZERr7RJkWr6kL8rYHSH2MmCc32LC-2awquTQVE6BVivqr2lZ2UBob7En2qNBTaEz5GlIpiib3bPTUSGUUVjrm-6M_VwjEIy_MEYc-Gn5vM_ff0BfFaCriJRwh5sALJT75IZ0n0A30zS_epCDqUiwv7Eo948DpYTfQbt-OUgrUqUzRlSGWSzBpVKhxwsYq_uCxNoX8OJOwjIIqi4JZQykLeTR34vOikNsZ2IPd8eGVm53PQQPFOtNiM2YnGASSoRCFMEGZW7Kjieq_jzu_AP4OI8eLB-YqH4bgHOj7GnO_PSBlQV0T4wvhwSMZ8xn4f90c0XPcl3VZrqiKMWv5-DRfhc3dSwOnKjINOPRC634BPrlfUUsq0ouuylZ26r7twZ3vs4wQ98u8n0QcrSgRRejDagZdRaYZeyCF1wDx-d1-OdRpF6irI1KXlfGLaSJ9QAkeKCB7PkLjDMC7q2dUnoMQrr1JYtnBp0xM8Qk2u5L5eJ5H7SkUGa9xaaOBdPA4dRmvp8ocoASotThBNd4G0k",
  FO_CLIENT: "",
  LINK_ASSETS: "",
  NODE_ENV: "production",
  IS_GV: false,

  // Player
  PLAYER_INGRESS: "",


  // Studio-live variables
  WEBINARPLEASE_BO_HOST: "",
  PION_API_HOST: "https://cloud-webinar.webtv-solution.com",
  CHROMUIM_API: "//webinar-stream.webtv-solution.com/",
  API_HOST_LIVE: "",
  API_WS_STUDIO_LIVE: "",
  HAS_DIFFUSION_ENCODER: true,
  FIREBASE_ENABLED: 1,
  FIREBASE_API_KEY: "AIzaSyB_bI3mZGycipmpIZOqTlB5KlgjtIF2uZE",
  FIREBASE_AUTH_DOMAIN: "webinar-cb35c.firebaseapp.com",
  FIREBASE_DATABASE_URL: "",
  FIREBASE_PROJECT_ID: "",
  FIREBASE_STORAGE_BUCKET: "webinar-cb35c.appspot.com",
  FIREBASE_MESSAGING_SENDER_ID: "903892353296",
  FIREBASE_APP_ID: "1:903892353296:web:1a233503c788d945432595",
  HAS_LOGIN: true,
  NAME_MODERATOR: false,
  HAS_PUBLIC_CHAT:true,
  PUBLISH_QUESTION: true,
  LIKE_QUESTION: true,
  REPLY_QUESTION: false,
  FAVORITE_QUESTION: true,
  HAS_NEW_DESIGN: true,
  HAS_OPTIONS: true,
  HAS_TRANSLATOR:false,
  HAS_MEET: true,
  LOGO: "emp",

  //Ws-studio-live varibles
  PREFIX: "live",
  FIREBASE_SERVICE_ACCOUNT_KEY_PATH_TYPE: "service_account",
  FIREBASE_SERVICE_ACCOUNT_KEY_PATH_PRIVATE_KEY_ID: "c7bf0b30a1ab4b184048b23670a7acdb5503ff42",
  FIREBASE_SERVICE_ACCOUNT_KEY_PATH_PRIVATE_KEY:"-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDU9+u/NTygiA7O\npU70mEAwX4xgLcsA4J2LqdV+wXslsyfiitqBYOvKfFFt7bUtxy0EAFGeMGPSVX09\n6Culpf2YT7t/UzItXeMdH4qI1jeBgZkumnGrwmpLBGqBMYPrAcZ2ttFjzGasXhF1\nyeWGizUjLsEmBoGpgFzfVsJ8K+vgh61Kygdwxhzbq1uv9UO1Eyc2eIMe+Jl5lnAt\nc1afIcByktMYW097ZIwxn/i0/SMRUaP2tTYBZUUaEUUAd+q2Lc0ChzVE5T0ZZX6z\nHSL5AULzCjwGq62Kl5MQjnMuGUN87cY5ucxycskb/aOI3JzkFJxXrzDz+FRuGeYE\nes+xDCHDAgMBAAECggEAAzSqKUopW/dAVc76NcdW7AN8LvRxglVfObt4Oby62FHu\nj0PszxbA5d7iMOfrmsFNRaqlj96FmiRsSrLEeW1auGC2309GyZUItqcDZFTpJ/tl\n7WQowt+dzvxFiCVRKYfwz1eoJjrs7rpJw/6ihFhzF9D72lEtwXnrq/b33XuhxNLu\n+C0+/5Hm4NcqtCRhy+jLCEdP8mjSImJKtxaOeIkRp1nz20CCTCev7Oy5BeFOe+YY\nv7aYufgLNhbAPZKkvN/NGg0xiErg5DhsxXXAhO3p2cyXWvRDShrprMfhJZOBSs4o\nZBRqR2NYfr461bgOZ1apCynVfWWIj6HHb+CEqla8YQKBgQD2cLictTzrzrYHtVPk\nBMtVlSnQKzFcev71fipqSm9nGhKojWbSY91VTtAiHCLm2o2ICzOXyXfVVVnjCsYX\naEOhGMHZXmwnejV2zM9AUKB3HCRRKEsAtoRGorM9PMLsYfRgACJ31tmgNzCxAECA\np3IiHXgJNVRnWcqL3TP1U1po4QKBgQDdOs6MpT6ZDG13c2grUrTTTFxDspLOpwdM\np23jGDjaNbMayi3TdsUVxTClhjZk5xPeNR2YXNYqDq9AqMgxHDWxd0DcW6lvH/Lr\ng46jCEgzU3mI5PbdQpJAs4TQnkG+b9hDAClWebNmX3y9m7Yoly9J4DMX2uuPWxDs\neQgsBFIrIwKBgQC2FRbyAh7fA74O9/Th1oD93NYmDqPL9ryGWg+wtQqWnPcIPmGo\nNU0FiNPTQAKxmu1ThSM7lQnLP+LIE9/Ez66YSph/qHo0uODjw/OlS20kVFCo/iJc\nhn45SmEwb8J9sOpEPBU7gi0NaXm6bbs6031Ko4dbFmIkcn1q1adcLZfxQQKBgGf/\nNQm4xGx+AxecEOQMxSRvSRAp6glrS2slaerPgCpUBBYb5N77fjp1sCCfrwJP/m7+\n2SK+wY6TyJ7EuRcdA8ChJsRv8n5irnc4KN1LD2XRX9iSidoJaWcw/MZ1HsK/IRKe\nCNC19Teyu5kGkeMS7w/zbRFRwFprnNENNGI8xcOxAoGBAKMMslSWhQvRmZTDIBGC\nQ0Yrv28Mtl27j4D7g8G4P8es/x1D2FsvZt3UV5BQtKtioy4qkHaXMpgIIiGZ8pg2\n+TmEqHJPCS3192FQB3vu6UhhFe3QaF8JmetjXR9WCWNHgHegDUNjOFPCo/S5K3x8\nLV594BFQYymt27G8nw5niNrI\n-----END PRIVATE KEY-----\n",
  FIREBASE_SERVICE_ACCOUNT_KEY_PATH_CLIENT_EMAIL:"firebase-adminsdk-yv10d@webinar-cb35c.iam.gserviceaccount.com",
  FIREBASE_SERVICE_ACCOUNT_KEY_PATH_CLIENT_ID:"108246440529606956530",
  FIREBASE_SERVICE_ACCOUNT_KEY_PATH_AUTH_URI:"https://accounts.google.com/o/oauth2/auth",
  FIREBASE_SERVICE_ACCOUNT_KEY_PATH_TOKEN_URI:"https://oauth2.googleapis.com/token",
  FIREBASE_SERVICE_ACCOUNT_KEY_PATH_AUTH_PROVIDER_X509_CERT_URL:"https://www.googleapis.com/oauth2/v1/certs",
  FIREBASE_SERVICE_ACCOUNT_KEY_PATH_CLIENT_X509_CERT_URL:"https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-yv10d%40webinar-cb35c.iam.gserviceaccount.com",

// webservice-live old variables
firebase_apiKey: "AIzaSyB_bI3mZGycipmpIZOqTlB5KlgjtIF2uZE",
firebase_authDomain: "webinar-cb35c.firebaseapp.com",
firebase_databaseURL: "https://test-live-9019c.firebaseio.com/",
firebase_projectId: "webinar-cb35c",
firebase_storageBucket: "webinar-cb35c.appspot.com",
firebase_messagingSenderId: "903892353296",
firebase_appId: "1:903892353296:web:1a233503c788d945432595",

  // Check utility of this variables
  API_GO: "",
  URL_INSCRIPTION: "",
  GA_ID: "",
  CLOUD_FLARE: "",
  FLASH_SWF_URL: "",
  API_HOST_GQ: "",
  API_CHECK_TOKEN: "",
  API_SECURE_URL: "",
  G_STREAMER_HOST: "https://gstreamer.webtv-solution.com/",
  API_HOST: "",
  API_SYMFONY_HOST: "",
  //player-p2p
  hrefPlayer: "",
  PLAYER_VERSION: "v@0.0.65",
  baseHref: "",
  CUSTOM_FIELD_API:"https://api-video.empreinte.tech/video-api"
}


const convert_module_to_string = (module_object) => {
  let result = '';

  Object.entries(module_object).forEach(([key, value]) => {
    result += `${key}: {\n`;

    Object.entries(value).forEach(([innerKey, innerValue]) => {
      const boolValue = innerValue === "true" ? true : innerValue === "false" ? false : innerValue;
      result += `  ${innerKey}: ${typeof boolValue === 'boolean' ? boolValue : Number.isInteger(Number(boolValue)) ? Number(boolValue) : `"${boolValue}"`},`;
      result += "\n"
    });
    result += '  },\n';
  });

  return result;
}

const convert_config_to_string = (config_object) => {
  if (!config_object) {
    return "";
  }
  const configString = Object.entries(config_object)
    .map(([key, value]) => {
      const boolValue = value === "true" ? true : value === "false" ? false : value;
      return `${key}: ${typeof boolValue === 'boolean' ? boolValue : Number.isInteger(Number(boolValue)) ? Number(boolValue) : `"${boolValue}"`},`;
    })
    .join('\r\n');
  return configString;
}

const parseConfString = (conf_string) => {
  const confObj = {};
  conf_string.split(",").forEach((kv) => {
    const [key, ...value] = kv.split(/:(.+)/);
    const trimmedKey = key.trim();
    if (trimmedKey === '') {
      return; // skip iteration if key is empty
    }
    confObj[trimmedKey] = value.join("").trim().replace(/(^")|("$)/g, "");
  });
  return confObj;
}

const parseModuleString = (moduleString) => {
  const array = moduleString.split('},');

  let obj = {};
  for (let i = 0; i < array.length; i++) {
    let str = array[i].replace(/,\n$/, ''); // remove trailing comma and new line
    let prop = str.substring(0, str.indexOf(':')).trim(); // extract property name and trim whitespace
    let valueStr = str.substring(str.indexOf(':') + 1).trim(); // extract property value as a string and trim whitespace
    obj[prop] = valueStr; // assign value to object property
  } 

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] === '') {
        delete obj[key];
      } else {
        const value = obj[key];
        const cleanedValue = value.replace(/\n/g, '').replace(/'/g, '"').replace(/(^\{|\}$)/g, '');
        const objectValue = {};
        cleanedValue.split(',').forEach(prop => {
          const [propName, propValue] = prop.split(':').map(part => part.trim().replace(/"/g, ''));
          objectValue[propName] = propValue;

        });
        obj[key] = objectValue;
      }
    }
  }
  Object.keys(obj).forEach((key) => {
    delete obj[key][""];
  });
  return obj;
}

function convert_module_string_To_Object(step2_module, obj_database) {
  const modules = ['dashboard', 'abonnement', 'media', 'profiles', 'interactivity', 'classifying', 'configuration', 'boitier', 'specifics', 'stats', 'display', 'skin'];

  const New_step2_module = { ...step2_module };
  const modKeys =Object.keys(obj_database);
  modules.forEach((elem) => {
    if (!obj_database[elem]) {
      New_step2_module[elem].disabled = true;
    } else {
      const regexp = new RegExp(`^(${elem}\\.|${elem}_)`);
      modKeys.forEach((key) => {
        if (key.startsWith('"') && key.endsWith('"')) {
          const keyTest =  key.slice(1, -1);
          if (regexp.test(keyTest)) {
            New_step2_module[elem].selected.push(keyTest);
          }
        }
        if (regexp.test(key)) {
          New_step2_module[elem].selected.push(key);
        }
      });
    }
  });
  for (const key in New_step2_module) {
    if (obj_database.hasOwnProperty(key)) {
      const obj = obj_database[key];
      const newValues = { ...obj };
      if (New_step2_module[key].hasOwnProperty("disabled")) {
        newValues.disabled = New_step2_module[key].disabled;
      }
      if (New_step2_module[key].hasOwnProperty("selected")) {
        newValues.selected = New_step2_module[key].selected;
      }
      New_step2_module[key] = { ...New_step2_module[key], ...newValues };
    }
  }
  return New_step2_module;
}
const Strctured_config = (name,ApiDNS,BoDNS,FoDNS) => {
  const config = {
  FO_CLIENT: `//${FoDNS}`,
  LINK_ASSETS: `https://${name}-client.s3.fr-par.scw.cloud`,
  API_HOST_GLOBAL: `//${ApiDNS}`,
  URL_Configuration_Server : `//${BoDNS}/configuration/serveur`,
  URL_Profile_IP : `//${BoDNS}/profile/ip`,
URL_Profile_User: `//${BoDNS}/profile/user`,
URL_Configuration_Client: `//${BoDNS}/configuration/client`,
URL_Profile_Group: `//${BoDNS}/profile/group`,
  API_PLAYER: `//${ApiDNS}/player`,
  API_HOST: `//${ApiDNS}`,
  API_HOST_LIVE: `//${ApiDNS}`,
  API_SYMFONY_HOST: `//k8s-scaleway-${name}-api-v4.webtv-solution.com`,
  API_SIGNALER: `//${name}-signaler.webtv-solution.com`,
  URL_Chutier: `//${BoDNS}/chutier`,
  URL_Classement: `//${BoDNS}/classement`,
  URL_Media: `//${BoDNS}/ws-media`,
  URL_metaData: `//${BoDNS}/metaData`,
  API_HOST_STATS: `//${ApiDNS}/stat`,
  FO_STATS_URL: `//${BoDNS}/stats`,
  PODCAST_INGRESS: `//${BoDNS}/podcast`,
  API_HOST_DAM: `https://${ApiDNS}`,
  PLAYER_INGRESS: `//${BoDNS}/player`,
  PIPER_ENDPOINT: `${ApiDNS}/transcoder`,
  SERVERLESS_PIPER_ENDPOINT: `${ApiDNS}/transcoder`,
  API_HOST_UPLOAD: `https://${ApiDNS}/upload/api/`,
  BUCKET_URL_THUMBNAIL: `https://s3.fr-par.scw.cloud/${name}-data.empreinte.tech/vignettes/moyen/`,
  PODCAST_S3_URL: `https://s3.fr-par.scw.cloud/${name}-vod.webtv-solution.com/podcast/`,
  API_HOST_DOWNLOAD: `https://${ApiDNS}/upload/`,
  FIREBASE_DATABASE_URL: `https://${name}-cb35c-471b8.firebaseio.com/`,
  API_WS_STUDIO_LIVE: `//${ApiDNS}`,
  firebase_databaseURL: `https://${name}-cb35c-471b8.firebaseio.com/`,
  hrefPlayer: `//k8s-scaleway-${name}-player.webtv-solution.com`,
}
  return name ? config : Object.keys(config);
};

export { step1_data, step2_config, step2_module, convert_module_to_string, convert_config_to_string, parseConfString, parseModuleString, convert_module_string_To_Object,Strctured_config }